import Footer from "./Footer";
import FooterPage from "./FooterPage";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

const Layout: React.FC = (props) => {
    return (
        <div id="wrapper">
            {/* <Sidebar></Sidebar> */}
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Topbar></Topbar>
                    <div className="row page-setting">
                        <div className="col-sm-2 sidebar-sm display-tog">
                        <Sidebar></Sidebar>
                        </div>
                        <div className="col-sm-10 padding-l-0">
                        {props.children}
                        </div>
                    </div>
                    
                    
                    

                </div>

                <FooterPage></FooterPage>

            </div>

        </div>
    );
};

export default Layout;

