import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';

type Props = {
    show: boolean;
    title: ReactNode;
    body: ReactNode;
    buttonPositive: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive: () => void;
    onNegative: () => void;
};

const ModalConfirmGenerico: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    return (
        <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <p className='icon-confirm color-blue'><i className="fa fa-exclamation-circle"></i></p>
                        </div>
                        <div className='col-sm-12'>
                            <p className='text-alert-center-bold'>{body}</p>
                        </div>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                {<Button variant="primary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                    {buttonNegative}
                </Button> }
                {/* <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                    {buttonPositive}
                </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default ModalConfirmGenerico;

