import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch } from "redux/store";
import { tokenAPICALL } from "services/authService";
import { setJWTToken } from "redux/actions";
import { setError } from "redux/slices/auth";
import { Alert, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
//import { getLoginByUsername } from "services/ws_loginService";
import { passwordAPICALL, passwordRecuperaAPICALL } from "services/passwordService";
import { Link } from "react-router-dom";
import NavigationMenu from "template/NavigationMenu";
import FooterPage from "template/FooterPage";

const TerminiCondizioni: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rData = useSelector((state: RootState) => state.authToken);
    
    const[passwordNoUguali, setPasswordNoUguali] = useState(null);
    const[passwordModificata, setPasswordModificata] = useState(null);


    const searchParam = window.location.search;
    const params = new URLSearchParams(searchParam);
    const accessToken = params.get('service-access')
    
    const formik = useFormik({
        initialValues: {
            confirmpassword: '',
            password: ''
        },
        onSubmit: async (values) => {
            //setInvioEmail(true)
            if(values.password != values.confirmpassword){
                setPasswordNoUguali(false)
            }else{
                passwordRecuperaAPICALL(values.password, accessToken).then((response) => {
                    if(response != null && response.data != null && response.data.document){
                        setPasswordModificata(true)
                    }else{
                        setPasswordModificata(false)
                    }
                })
            }            
        },
    });

    const handlePageLogin = () => {

        navigate('/', { replace: true });

    }

    useEffect(() => {
        setPasswordNoUguali(true)
        setPasswordModificata(null)
        document.body.classList.toggle('bg-gradient-primary', true);
    }, [])
    return (
        <div className="">
            <NavigationMenu isLoading={false}/>
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-12">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        <div className="text-center">
                                            {/* <h1 className="h4 text-gray-900 mb-4">Privacy Policy</h1> */}
                                            <div>
                                        
<div className="wk-page-content">
<p style={{textAlign: "justify"}}>Il presente sito web (di seguito definito “Portale”) è gestito da Tecno Innovis S.r.l. (di seguito "Gestore del Servizio"). La navigazione semplice (e senza richiesta dei servizi contenuti) sul Portale da parte dei singoli utenti è gratuita e costituisce l'accettazione dei termini e delle condizioni delle presenti note legali. Nel caso in cui l’utente non intenda accettare le presenti condizioni, è sufficiente che lasci la navigazione sulle pagine del Portale.</p>

<h3 style={{textAlign: "justify"}}>Condizioni Utilizzo: Responsabilità</h3>
<p style={{textAlign: "justify"}}>Il Gestore del Servizio non assume alcuna responsabilità in relazione ad eventuali problemi che possano insorgere per effetto dell’utilizzazione del Portale o di siti esterni ad esso collegati. Il Gestore del Servizio, salvo diverse indicazioni di legge, non potrà essere ritenuto in alcun modo responsabile dei danni di qualsiasi natura causati direttamente o indirettamente:</p>

<ul style={{textAlign: "justify"}}>
 	<li>dall’accesso al Portale</li>
 	<li>dall’incapacità o impossibilità di accedervi</li>
 	<li>dall’affidamento alle notizie in esso contenute o dal loro impiego</li>
</ul>
<p style={{textAlign: "justify"}}>Il Gestore del Servizio si riserva il diritto di modificare i contenuti del Portale e dei presenti termini e condizioni in qualsiasi momento e senza alcun preavviso.</p>

</div>
<h3 style={{textAlign: "justify"}}>Condizioni Utilizzo: Credenziali di accesso al Portale e Responsabilità dell’Utente.</h3>
<p style={{textAlign: "justify"}}>A seguito della accettazione delle presenti condizioni di utilizzo, l’Utente riceverà, sulla casella di posta elettronica indicata nel modulo di registrazione, apposite credenziali (username e password) per l’accesso al Servizio. L’Utente potrà in ogni momento, per ragioni di sicurezza, procedere alla variazione della propria password. Inoltre, è onere dell’Utente custodire la propria username e la relativa password per l'accesso al Portale, adottando tutte le misure idonee ad impedirne la conoscibilità a soggetti terzi e l'utilizzo non autorizzato. A tal fine, l'Utente esonera espressamente il Gestore del Servizio da qualsiasi tipo di responsabilità derivante dall'utilizzo illegittimo o non autorizzato da parte di terzi della username e relativa password di competenza dell'Utente stesso.
Il Gestore del Servizio si riserva in ogni momento la possibilità di mettere a disposizione dell’Utente ulteriori procedure di autenticazione sicura per l’accesso al Portale, dandone comunicazione tramite email all'indirizzo indicato in fase di registrazione al servizio. E' facoltà del Gestore del Servizio, verificare la veridicità della informazioni inserite nella registrazione al Portale. Qualora il Gestore del Servizio, ritiene che le informazioni non sono veritiere, lo stesso potrà procedere alla cancellazione del profilo, inibendo l'accesso al Portale, in modo unilaterale e senza preavviso, per tutelare la sicurezza del Portale, in alternativa, potrebbe richiedere se lo ritiene opportuno, la rettifica dei dati inseriti in fase di registrazione, inviando una comunicazione all'indirizzo email inserito in fase di registrazione. Qualora, a fronte della richiesta di rettifica dei dati, non seguiranno risposte entro 3 giorni lavorativi, è facoltà del Gestore del Servizio procedere con la cancellazione dei dati ed inibire l'accesso al Portale per motivi di sicurezza.</p>

<h3 style={{textAlign: "justify"}}>Condizioni Utilizzo: Disponibilità del Servizio e modalità di accesso</h3>
<p style={{textAlign: "justify"}}>L’accesso ai Servizi Camerali del Portale saranno disponibili dalle ore 08:00 alle ore 21.00 di tutti i giorni feriali ed il sabato dalle 08:00 alle 14:00, salvo estensioni comunicate dal Gestore del Servizio. E’ onere dell’Utente dotarsi degli adeguati dispositivi hardware e della connessione internet per l’accesso al Portale.</p>

<div className="wk-page-content" style={{textAlign: "justify"}}>
<h3>Condizioni Utilizzo: Collegamenti a siti esterni</h3>
I collegamenti a siti esterni presenti nel Portale sono forniti come puro e semplice servizio agli utenti, pertanto si esclude da ogni responsabilità sulla correttezza e sulla completezza dell’insieme dei collegamenti indicati.
L’indicazione dei collegamenti non implica da parte del Gestore del Servizio alcun tipo di approvazione, condivisione di responsabilità in relazione alla legittimità, alla completezza e alla correttezza delle informazioni contenute nei siti indicati.

</div>
<h3 style={{textAlign: "justify"}}>Condizioni Utilizzo: Limitazione di responsabilità</h3>
<p style={{textAlign: "justify"}}>Il Gestore del Servizio si impegna a prestare con diligenza le attività di propria competenza garantendo l’accesso al Portale salvo indisponibilità determinata da caso fortuito, forza maggiore o comunque da cause non imputabili al Gestore del Servizio stesso. In particolare, l’Utente prende atto ed accetta di esonerare il Gestore del Servizio da ogni responsabilità per danni derivanti dall'indisponibilità e/o dal non corretto
funzionamento degli strumenti di pagamento messi a disposizione nella pagina del proprio negozio online dovuti a cause imputabili ai gestori dei sistemi di pagamento o comunque non ascrivibili al Gestore del Servizio.
Inoltre il Gestore del Servizio, garantisce esclusivamente la corrispondenza dei dati forniti con quelli contenuti negli archivi ufficiali delle Camere di Commercio alla data indicata al momento dell’estrazione. L’Utente pertanto prende atto ed accetta di esonerare il Gestore del Servizio da ogni responsabilità per danni di qualsiasi natura derivanti da eventuali inesattezze od incompletezza delle informazioni contenute nei risultati di ricerca anagrafica (con filtri o unica), informazioni di dettalio e/o nei documenti erogati nella sezione archivio del portale (servizi contenuti nella sezione "Archivio Documenti" della propria area privata). L’Utente pertanto prende atto ed accetta di esonerare il Gestore del Servizio da ogni responsabilità, per gli eventuali ritardi e/o non erogazione dei servizi in differita (servizi contenuti nella sezione "Documenti in Attesa" della propria area privata) rispetto al tempo di consegna comunicato, in quanto i tempi di erogazione sono in riferimento e dipendono esclusivamente dai fornitori dei servizi.  L’Utente pertanto prende atto ed accetta di esonerare il Gestore del Servizio da ogni responsabilità, in caso di richiesta documenti e/o servizi con dati non corretti che potrebbero non generare documenti idonei per la successiva erogazione, accettando altresì che il costo (o decurtazione del credito disponibile) del servizio non erogato per dati comunicati non corretti sia correttamente addebitato e detratto dal proprio credito. 
L'Utente prende atto che anche se le ricerche anagrafiche non hanno prodotto risultato in quanto la ricerca unica e/o l'impostazione dei filtri inseriti non producono risultato presso i registri dei relativi fornitori di cui il servizio fa parte, il relativo addebito sarà effettuato secondo i costi previsti.
L’Utente prende atto ed accetta che il Gestore del Servizio non assume alcuna obbligazione né presta alcuna garanzia che non sia espressamente prevista nelle presenti condizioni generali.</p>

<h3 style={{textAlign: "justify"}}>Condizioni Utilizzo:  Variazioni delle condizioni generali</h3>
<p style={{textAlign: "justify"}}>L’Utente prende atto ed accetta che potranno essere modificate, liberamente ed in ogni momento, le presenti condizioni generali, nonchè le caratteristiche e le modalità di erogazione del Servizio, variando in particolare anche le modalità di accesso al Portale ed ai relativi servizi. Le predette modifiche saranno aggiornate sul Portale alla seguente pagina.
In conseguenza di dette modifiche, l’Utente potrà recedere liberamente dal Portale inviando comunicazione al seguente indirizzo email: privacypolicy@myimprese.it
Inoltre, l’Utente prende atto ed accetta che sarà facoltà del Gestore del Servizio modificare ed integrare il Listino dei Servizi Forniti contenente l’indicazione del nome dei servizi e degli oneri connessi.</p>

<h3 style={{textAlign: "justify"}}>Condizioni Utilizzo: Obblighi ed oneri dell’Utente</h3>
<p style={{textAlign: "justify"}}>L’Utente prende atto che è espressamente vietata la rivendita, la distribuzione informatica e/o la riproduzione e/o diffusione per copie in qualunque forma (meccanica, cartacea, informatica, etc.) di dati e documenti estratti tramite accesso al Servizio. E’ inoltre espressamente vietato e perseguibile, ai sensi della normativa vigente, l’utilizzo dei dati estratti attraverso il Portale per inviare bollettini di conto corrente o documenti analoghi
che nel contenuto e nella forma possano indurre i destinatari a ritenere erroneamente che il pagamento rappresenti un adempimento obbligatorio nei confronti di qualsiasi Amministrazione, ivi comprese le Camere di Commercio.
In considerazione della titolarità da parte del Gestore del Servizio delle soluzioni informatiche funzionali all'erogazione del servizio, l'utilizzo dello stesso deve svolgersi nel rispetto dei diritti di proprietà intellettuale del Gestore del Servizio.</p>

<div className="wk-page-content">
<h3 style={{textAlign: "justify"}}>Condizioni Utilizzo: Diritto d'autore e copyright</h3>
<p style={{textAlign: "justify"}}>Il Portale può contenere immagini, documenti, loghi e marchi di terze parti che hanno espressamente autorizzato il Gestore del Servizio alla pubblicazione nel Portale. Il materiale di terzi è protetto dalla normativa sul diritto d'autore e a tutela dei marchi ed è coperto da copyright. Inoltre, fatto salvo gli utilizzi strettamente personali, non è consentito:</p>

<ul style={{textAlign: "justify"}}>
 	<li>copiare</li>
 	<li>alterare</li>
 	<li>distribuire</li>
 	<li>pubblicare</li>
 	<li>utilizzare</li>
</ul>
<p style={{textAlign: "justify"}}>i contenuti del presente Portale senza autorizzazione specifica del Gestore del Servizio.</p>

<h3 style={{textAlign: "justify"}}>Condizioni Utilizzo: Privacy e Cookies</h3>
<p style={{textAlign: "justify"}}>Per maggiori informazioni e dettagli in merito all'utilizzo dei cookie si rimanda all'apposita sezione  <a className="color-blue" href="https://myimprese.it/privacy-policy/">Privacy Policy</a></p>

</div>

</div>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <FooterPage />

        </div>
        
    );
};

export default TerminiCondizioni;


