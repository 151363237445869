import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';

type Props = {
    show?: boolean;
    title: ReactNode;
    body?: ReactNode;
    buttonPositive?: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive?: () => void;
    onNegative: () => void;
};

const ConfirmationInfoCrediti: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    return (
        <Modal size="lg" show={true} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='row'>
                                <div className='col-sm-12'>
                                    <p className='icon-alert color-blue'><i className="fa fa-circle-info"></i></p>
                                </div>
                                <div className='col-sm-12'>
                                    <p className=''>
                                    Attraverso i crediti è possibile richiedere i servizi e le informazioni sulla piattaforma. Ciascuna richiesta ha un costo specifico che verrà scalato dal saldo personale
                                    </p>
                                    <p>In particolare:</p>
                                    <ul>
                                        <li><strong>Ricerca Imprese:</strong> questi crediti permettono di 
                                            effettuare le ricerche dalla sezione Ricerca Imprese. Ogni ricerca lanciata costa 1 credito</li>
                                        <li><strong>Dettaglio Imprese:</strong> dati risultati di Ricerca Impresa è 
                                            possibile accedere al dettaglio delle informazioni per l'impresa selezionata. La visualizzazione del dettaglio di ciascuna impresa costa 1 credito</li>
                                        <li><strong>Anagrafiche in File:</strong> la lista delle imprese ottenute da una ricerca è possibile scaricarla su file excel nel limite 
                                            dei crediti di questa sezione. Ogni anagrafica presente nel file da scaricare vale 1 credito. es, un file con 50 anagrafiche costerà 50 crediti
                                        </li>
                                        <li><strong>Visure Camerali, Bilanci Azienda, Report Smart, Report Azienda(Deep Dossier),  Report Azienda Approfondito(Executive Report):</strong> costano ciascuno 1 credito, e per ciascuno è previsto un saldo specifico. </li>
                                    </ul>   
                                        <p>È possibile ricaricare il proprio saldo acquistando crediti o pacchetti di crediti specifici dalla sezione Shop</p>
                                    

                                </div>
                            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                    {buttonNegative}
                </Button>
                {/* <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                    {buttonPositive}
                </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationInfoCrediti;

