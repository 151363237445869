import React from 'react';
import img_logotecno from "../asset/TecnoinnovisLogo.png"
import img_logopaypal from "../asset/logo_pp.png"
import img_logonexi from "../asset/logo_n.png"
import img_logomyimpresawhite from "../asset/logo_mi_white.png"

const FooterPage = () => {
  return (
    <>
      <div className="row box-footer-bg">
        <div className="col-sm-12">
          <div className="row box-footer-space">
            <div className="col-sm-4">
              <p className="box-footer-col1-text">MyImprese è un servizio</p>
              <img src={img_logotecno} alt="Logo Tecno" className="box-footer-logo-tecno space-sm" />
            </div>
            <div className="col-sm-4">
              <p className="box-footer-link">
                <a href="/privacy-policy" className="box-footer-link-color">Privacy Policy</a>
              </p>
              <p className="box-footer-link">
                <a href="/termini-condizioni" className="box-footer-link-color">Termini e Condizioni</a>
              </p>
              <p className="box-footer-link">
                <a href="/assistenza" className="box-footer-link-color">Contattaci</a>
              </p>
            </div>
            <div className="col-sm-4">
              <p className="box-footer-text-col3">Gestiamo pagamenti sicuri sulle piattaforme</p>
              <p className="image-right">
                <img src={img_logopaypal} alt="Logo PayPal" className="box-footer-logo-tecno box-footer-logo_1" />
              </p>
              <p className="image-right">
                <img src={img_logonexi} alt="Logo Nexi" className="box-footer-logo-tecno box-footer-logo_2" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row box-copy">
        <div className="col-sm-12 p-top-20">
          <p className="t-align-center box-copy-text">
            <img src={img_logomyimpresawhite} alt="Logo MyImprese White" className="box-copy-logo" /> - 2025
          </p>
        </div>
      </div>
    </>
  );
};

export default FooterPage;
