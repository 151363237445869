import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { array } from 'yup';

type Props = {
    show: boolean;
    title: ReactNode;
    body: ReactNode;
    buttonPositive?: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive?: () => void;
    onNegative: () => void;
};

const ConfirmationAggiuntaPacchettoErrore: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    if(body != null){
        console.log(body)
            return (
                <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                                <div className='col-sm-12'>
                                    <p className='icon-alert color-gray-pg'><i className="fa fa-exclamation-circle"></i></p>
                                </div>
                                <div className='col-sm-12'>
                                    <p className='text-alert-center-bold'>{body}</p>
                                </div>
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                            {buttonNegative}
                        </Button>
                        {/* <Button onClick={() => onPositive()} variant="success" disabled={disableButton}>
                            {buttonPositive}
                        </Button> */}
                    </Modal.Footer>
                </Modal>
            );    
    }else{
        return (
            <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                            <div className='col-sm-12'>
                                <p className='icon-alert color-gray-pg'><i className="fa fa-exclamation-circle"></i></p>
                            </div>
                            <div className='col-sm-12'>
                                <p className='text-alert-center-bold'>Abbiamo rilevato un errore nella compilazione del tuo ordine! Riprova</p>
                            </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

};

export default ConfirmationAggiuntaPacchettoErrore;

