import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';

type Props = {
    show?: boolean;
    title: ReactNode;
    body?: ReactNode;
    buttonPositive?: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive?: () => void;
    onNegative: () => void;
};

const ConfirmationInfoSalva: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    return (
        <Modal size="lg" show={true} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='row'>
                                <div className='col-sm-12'>
                                    <p className='icon-alert color-blue'><i className="fa fa-info-circle"></i></p>
                                </div>
                                <div className='col-sm-12'>
                                    <p className=''>
                                    Salvando la ricerca potrai:
                                    </p>
                                    
                                    <ul>
                                        <li><strong>Riutilizzare</strong> i filtri scelti per effettuare nuovamente la ricerca senza dover reinserire i dati. Questa funzione è particolarmente utile per ricerche ricorrenti.</li>
                                        <li><strong>Scaricare le anagrafiche</strong>  in un file, per un loro riutilizzo, analisi o altre elaborazioni.</li>
                                    </ul>   
                                        <p className='font-12'><strong>Nota Importante:</strong> Per salvare la ricerca in modo ottimale, ti consigliamo di non visualizzare dettagli specifici, come il dettaglio delle imprese. Ricorda inoltre che il salvataggio della ricerca è soggetto a scadenza.</p>
                                    

                                </div>
                            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                    {buttonNegative}
                </Button>
                {/* <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                    {buttonPositive}
                </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationInfoSalva;

