import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';

type Props = {
    show?: boolean;
    title: ReactNode;
    body?: ReactNode;
    buttonPositive?: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive?: () => void;
    onNegative: () => void;
};

const ConfirmationInfoEsponenti: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    return (
        <Modal size="lg" show={true} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='row'>
                                <div className='col-sm-12'>
                                    <p className='icon-alert color-blue'><i className="fa fa-info-circle"></i></p>
                                </div>
                                <div className='col-sm-12'>
                                    <p>
                                    Gli esponenti sono le persone che ricoprono ruoli di responsabilità 
                                    all'interno di un'organizzazione o azienda, come amministratori, dirigenti, 
                                    rappresentanti legali o membri del consiglio di amministrazione. 
                                    Essi sono responsabili della gestione e delle decisioni operative o strategiche, 
                                    indipendentemente dal possesso di quote societarie.
                                    </p>

                                </div>
                            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                    {buttonNegative}
                </Button>
                {/* <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                    {buttonPositive}
                </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationInfoEsponenti;

