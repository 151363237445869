import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';

type Props = {
    show?: boolean;
    title: ReactNode;
    body?: ReactNode;
    buttonPositive?: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive?: () => void;
    onNegative: () => void;
};

const ConfirmationInfoConsultabili: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    return (
        <Modal size="lg" show={true} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='row'>
                                <div className='col-sm-12'>
                                    <p className='icon-alert color-blue'><i className="fa fa-info-circle"></i></p>
                                </div>
                                <div className='col-sm-12'>
                                    <p>
                                    Questo elenco rappresenta le aziende disponibili (fino a un massimo di 100) per la consultazione online del dettaglio, che include:
                                    </p>
                                    <ul>
                                        <li><strong>Anagrafica Azienda</strong>: Include la localizzazione della sede legale e i contatti principali.</li>
                                        <li><strong>Bilanci Aziendali</strong>: Questa sezione presenta i dati principali relativi agli ultimi <strong>3 bilanci</strong> depositati.</li>
                                        <li><strong>Soci ed Esponenti Aziendali</strong>: Contiene le informazioni principali relative ai soci e agli esponenti dell'azienda.</li>
                                        <li><strong>Sedi e Unità Locali</strong>: Riporta i dati delle unità locali dell'azienda, specificando la destinazione d'uso e la loro posizione su mappa.</li>
                                        <li><strong>Partecipazioni Aziendali</strong>: Fornisce i dettagli principali sulle partecipazioni aziendali.</li>
                                        <li><strong>Documenti</strong>: Consente di richiedere report aziendali in formato PDF.</li>
                                    </ul>
                                    <p className='font-12'>
                                    <strong>Nota importante: </strong>per ottenere l'elenco completo delle aziende restituite dalla ricerca oltre i 100 risultati è necessario salvare ed acquistare la ricerca tramite il tasto <strong>Salva Ricerca</strong>
                                    </p>

                                </div>
                            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                    {buttonNegative}
                </Button>
                {/* <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                    {buttonPositive}
                </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationInfoConsultabili;

