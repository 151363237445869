import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "../styles/homepublic.scss"

import logo from "../asset/logo_myimprese.png"
import immaginePrincipale from "../asset/princiupal.png"
import img_screen_interno from "../asset/screen_interno.png"
import img_scree_interno_post from "../asset/screen_interno_post.png"
import img_commercialista from "../asset/testimonial_uno.jpeg"
import img_consulente from "../asset/testimonial_due.jpeg"
import img_webmarketing from "../asset/testimonial_tre.jpeg"
import img_jmyimprese from "../asset/Intersect.png"
import img_logotecno from "../asset/TecnoinnovisLogo.png"
import img_logomyimprese from "../asset/Logomyimprese.png"
import img_logomyvisure from "../asset/logo_myvisure.png"
import img_logopaypal from "../asset/logo_pp.png"
import img_logonexi from "../asset/logo_n.png"
import img_logomyimpresawhite from "../asset/logo_mi_white.png"
import svg_estrai from "../asset/icon_4.svg"
import svg_individua from "../asset/icon_6.svg"
import svg_ottieni from "../asset/icon_5.svg"
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setJWTToken } from "redux/actions";
import { RootState } from "redux/reducers";
import { setError } from "redux/slices/auth";
import { useAppDispatch } from "redux/store";
import { tokenAPICALL } from "services/authService";
import * as yup from 'yup';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Testimonial_responsive from "components/testimonial_responsive";
import PrezziPacchettiResponsive from "components/prezzi_pacchetti_responsive";
import NavbarPublic from "components/navbar_public";
import { parseJwtToken } from "services/tokenService";
import { getOneWs_Login } from "services/ws_loginService";


export const HomePagePublic = (): JSX.Element => {

    const[cssIndividua, setCssIndividua] = useState("col-sm-12 p-top-30 box-action-select")
    const[cssEstrai, setCssEstrai] = useState("col-sm-12 p-top-30")
    const[cssOttieni, setCssOttieni] = useState("col-sm-12 p-top-30")

    const[errorLogin, setErrorLogin] = useState(false)

    const handleClickAction = (numAction: number) => {
        setCssIndividua("col-sm-12 p-top-30")
        setCssEstrai("col-sm-12 p-top-30")
        setCssOttieni("col-sm-12 p-top-30")
        if(numAction === 1) setCssIndividua("col-sm-12 p-top-30 box-action-select")
        if(numAction === 2) setCssEstrai("col-sm-12 p-top-30 box-action-select")
        if(numAction === 3) setCssOttieni("col-sm-12 p-top-30 box-action-select")
    }

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.authToken);
    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        onSubmit: async (values) => {
            setErrorLogin(false)
            const response = await tokenAPICALL(values.username, values.password);
            if (response) {
                dispatch(setJWTToken(response));
                navigate('/ricerca_unica', { replace: true });
            } else {
                dispatch(setError("Invalid Credentials"))
                setErrorLogin(true)
            }
        },
        validationSchema: yup.object({
            username: yup.string().trim().required('Email campo obbligatorio'),
            password: yup.string().trim().required('Password campo obbligatorio'),
        }),
    });

    const handleRegister = () => {
        navigate('/registrazione', { replace: true });
    }

    const handleRecuperoPassword = () => {
        navigate('/reset-password', { replace: true });
    }

    const handleAssistenza = () => {
        navigate('/assistenza', { replace: true });
    }

    const handleAreaPrivata = () => {
        navigate('/ricerca_unica', { replace: true });
    }
    

    useEffect(() => {
        document.body.classList.toggle('bg-gradient-primary', true);
    }, [])

    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    var idUtente = 0
    let iValueUser = {
        id: '',
        id_utente: '',
        nome: '',
        cognome: '',
        azienda: '',
        email: '',
        telefono: '',
        descrizione: '',
        acceptTerms: false
    }
    const iValueEmpty = {
        id: '',
        id_utente: '',
            nome: '',
            cognome: '',
            azienda: '',
            email: '',
            telefono: '',
            descrizione: '',
            acceptTerms:false
    }

    const[datiLoad, setDatiLoad] = useState(null);

    const[id_utente, setIdUtente] = useState('');
    const[scaduto, setScaduto] = useState(false);

    // const getCurrentTimestampItaly = () => {
    //     const now = Date.now();
    //     console.log("Timestamp in millisecondi:", now);
      
    //     // Verifica della data corrispondente al timestamp in Italia
    //     const dateInItaly = new Date(now).toLocaleString("it-IT", { timeZone: "Europe/Rome" });
    //     console.log("Data in Italia:", dateInItaly);
      
    //     return now*1000;
    //   };

      const getCurrentTimestampInSecondsItaly = () => {
        // Ottieni la data attuale e il tempo in millisecondi
        const now = new Date();
        
        // Calcola il timestamp corretto in secondi per l'Italia
        const utcOffsetInSeconds = now.getTimezoneOffset() * 60;
        const currentTimestampInSeconds = Math.floor(Date.now() / 1000) - utcOffsetInSeconds;
      
        return currentTimestampInSeconds;
      };

    useEffect(() => {
        // console.log(getCurrentTimestampInSecondsItaly())
        // console.log("datiLoad")
        // console.log(jwtToken)

        // console.log(jwtToken.exp < getCurrentTimestampInSecondsItaly())

        let scaduto = false
        if(jwtToken && jwtToken.data != null){
            idUtente = jwtToken.data.id;
            if(datiLoad == null){
                try{
                    getOneWs_Login(idUtente).then((response) => {
                        if (response && response.data && response.data.document) {
                          console.log(response);
                          const iValueUser_set = {
                            id: '',
                            id_utente: response.data.document.id,
                            nome: response.data.document.nome_referente,
                            cognome: response.data.document.cognome_referente,
                            azienda: response.data.document.nome_azienda,
                            email: response.data.document.email,
                            telefono: response.data.document.telefono,
                            descrizione: '',
                            acceptTerms:false
                        }
                        setDatiLoad(iValueUser_set);
        
                        }else{
                            iValueUser = iValueEmpty
                            setDatiLoad(iValueUser);
                            setJWTToken(null)
                        }
                      })

                }catch(e){
                    iValueUser = iValueEmpty
                            setDatiLoad(iValueUser);
                            setJWTToken(null)
                }
                
            }
            
        }else{
            iValueUser = iValueEmpty
        }
      }, [datiLoad]);

    
  return (
            <>
            <div className="container-custom">
            {/* <div className="row menu-basic align-items-center px-custom">
                <div className="col-sm-6">
                    <img src={logo} alt="" className="logo-public" />
                </div>
                <div className="col-sm-6">
                    <div className="d-flex justify-content-end gap-3">
                        <p className="font-menu">Servizi</p>
                        <p className="font-menu">Prezzi</p>
                        <p className="font-menu">Chi Siamo</p>
                    </div>
                </div>
            </div> */}
        <NavbarPublic logo={logo}></NavbarPublic>
        <div className="row img-principal box-login-py-50">
            <div className="col-sm-6">
            <p className="style-text s1-image-text">
            Informazioni da grandi, alla portata di tutti
            </p>
            <p className="s1-text s2-image-text">
            Trova le aziende che ti interessano per area geografica, dimensione, fatturato, crea delle liste o acquisisci le informazioni che ti servono. In modo semplice e ad un prezzo accessibile anche per le piccole organizzazioni.
            </p>
            </div>
            
            <div className="col-sm-6">
            <div className="box-login background-linear">
                {((jwtToken == null || jwtToken.data == null) && datiLoad == null ) || (jwtToken.exp < getCurrentTimestampInSecondsItaly()) ? (

                    <>
                    <p className="box-login-title box-login-text">Accedi per usare il servizio</p>
              <Form className="text-box box-login-px-25" onSubmit={formik.handleSubmit}>
                <Form.Group>
                    <label className="label-text">Email</label>
                    <Form.Control type="text" name="username" className="" value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.touched.username && !!formik.errors.username}
                        isValid={!!formik.touched.username && !formik.errors.username}
                    ></Form.Control>
                    {formik.errors.username && (
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.username}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group>
                    <label className="label-text">Password</label>
                    <Form.Control type="password" name="password" className="" value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.touched.password && !!formik.errors.password}
                        isValid={!!formik.touched.password && !formik.errors.password}
                    ></Form.Control>
                    {formik.errors.password && (
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.password}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                {errorLogin ?
                <span className="position-sm-text">Email o password non corretti! Riprova</span>
                :
                <span className="box-login-span"><br /></span>
                }
                
                <Button type="submit" className="button-login text-button-login box-login-button position-sm accedi-pos" variant="primary">Accedi</Button>
                {/* <Button className="btn btn-info btn-user btn-block" onClick={handleRegister}>Registrati</Button> */}
                <div className="form-group">
                    <div>
                        {/* <Link to={"/reset-password"} className="curser-pointer">
                            <label className="marginb-15 curser-pointer">Password Dimenticata?</label>
                        </Link> */}
                        <p className="boxlogin-text-cred boxlogin-text">Non hai le credenziali?<br />
                        <span className="boxlogin-register"
                        onClick={handleRegister}
                        >Registrati</span> adesso e ottieni 10 ricerche gratis!</p>
                        
                    </div>
                    <div><p className="boxlogin-datamissed"
                    onClick={handleRecuperoPassword}
                    >Dati Dimenticati?</p></div>
                </div>
              </Form>
                    </>
                    
                ):(
                    <>
                    <p>Hai effettuato accesso come:</p>
                    {datiLoad != null && datiLoad.azienda != null &&
                        <>
                        <h3>{datiLoad.azienda}</h3>
                        <p onClick={handleAreaPrivata} className="boxlogin-datamissed">Vai alla tua area privata</p>
                        </>
                    }
                    
                    </>
                )}
              
            </div>
            </div>
            
        </div>
        <div className="row" id="servizi">
            <div className="col-sm-6 section-image">
                <div className="row">
                    <div className="col-sm-8 box-screen-img-prima">
                        <img src={img_screen_interno}  className="box-screen-img"/>
                    </div>
                    <div className="col-sm-4 box-screen-img-dim-post padding-ty-150">
                        <img src={img_scree_interno_post}  className="box-screen-img-dim-post"/>
                    </div>
                </div>
                
            </div>
            <div className="col-sm-6 box-action-bg">
                <div className="row">
                    <div className="col-sm-12 m-top-50">
                        <p className="box-action-title box-action-title-dim text-white-public p-left-12 p-sm-0 text-center-sm">Una piattaforma versatile per ricerche strategiche e informazioni dettagliate</p>
                    </div>
                    <div 
                    className={cssIndividua}
                    onClick={() => handleClickAction(1)}
                    onMouseOver={() => handleClickAction(1)}
                    >
                        <div className="row underline-link">
                            <div className="col-sm-2 dim-custom-20">
                                <img src={svg_individua} className="icon-svg box-action-individua-img" />
                            </div>
                            <div className="col-sm-8 dim-custom-80">
                                <p className="box-action-individua m-bottom-5">Individua</p>
                                <p className="box-action-individua-text text-center">un insieme di aziende con la ricerca avanzata, salva i risultati trovati e definisci il tuo target</p>
                            </div>
                        </div>
                    </div>
                    <div 
                    className={cssEstrai}
                    onClick={() => handleClickAction(2)}
                    onMouseOver={() => handleClickAction(2)}
                    >
                        <div className="row underline-link">
                            <div className="col-sm-2 dim-custom-20">
                                <img src={svg_estrai} className="icon-svg box-action-individua-img" />
                            </div>
                            <div className="col-sm-8 dim-custom-80">
                                <p className="box-action-individua m-bottom-5">Estrai</p>
                                <p className="box-action-individua-text">dati aggregati e storici, confronta il bilancio degli ultimi anni, visualizza le sedi sul territorio e le persone di riferimento.</p>
                            </div>
                        </div>
                    </div>
                    <div 
                    className={cssOttieni}
                    onClick={() => handleClickAction(3)}
                    onMouseOver={() => handleClickAction(3)}
                    >
                        <div className="row underline-link">
                            <div className="col-sm-2 dim-custom-20">
                                <img src={svg_ottieni} className="icon-svg box-action-individua-img" />
                            </div>
                            <div className="col-sm-8 dim-custom-80">
                                <p className="box-action-individua m-bottom-5">Ottieni</p>
                                <p className="box-action-individua-text">documenti specifici sulla singola azienda, Visure, Bilancio, Catasto, Stato Patrimoniale.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row box-bg-light">
            <div className="col-sm-12 p-top-100 p-bottom-100">
                <p className="box-info box-info-text box-color-black">MyImprese è un servizio destinato sia alla tradizionale platea di commercialisti, consulenti del lavoro e studi legali, sia a tutti i professionisti che desiderano esplorare il panorama aziendale a vari livelli, per ricerche di mercato e opportunità di business.</p>
            </div>
        </div>

        <div className="row box-testimonial control-d">
            <div className="col-sm-12  p-top-50 ">
                <div className="row box-testimonial-dim center-block">
                    <div className="col-sm-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <img src={img_commercialista} className="box-testimonial-img-1 box-testimonial-pos-img-text"/>
                                <p className="box-testimonial-title">Marco <br /><span className="box-testimonial-subtitle">Commercialista</span></p>
                            </div>
                            <div className="col-sm-12">
                                <p className="box-testimonial-text box-color-black">“Sto seguendo un nuovo cliente, mi interesserebbe conoscere la media del capitale versato negli ultimi anni da una particolare categoria di aziende.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <img src={img_consulente} className="box-testimonial-img-1 box-testimonial-pos-img-text"/>
                                <p className="box-testimonial-title">Piero <br /><span className="box-testimonial-subtitle">Consulente sulla sicurezza aziendale</span></p>
                            </div>
                            <div className="col-sm-12">
                                <p className="box-testimonial-text box-color-black">“Vorrei conoscere quali sono le aziende sul territorio con più di 20 dipendenti, potrebbero aver bisogno di una mia consulenza”</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <img src={img_webmarketing} className="box-testimonial-img-1 box-testimonial-pos-img-text"/>
                                <p className="box-testimonial-title">Arianna <br /><span className="box-testimonial-subtitle">Proprietaria di un'agenzia di web marketing</span></p>
                            </div>
                            <div className="col-sm-12">
                                <p className="box-testimonial-text box-color-black">“Voglio individuare le piccole aziende nate a Bologna negli ultimi 3 mesi, perchè potrebbero essere interessate a promuovere la loro attività”</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="control-r-t">
        <Testimonial_responsive></Testimonial_responsive>
        </div>
        





        <div className="row box-bg-light">
            <div className="col-sm-12 p-top-100">
                
            </div>
        </div>
        <div className="row box-piani-bg " id="prezzi">
            <div className="col-sm-12">
                <p className="box-piani-title p-top-80 p-bottom-30">Trova il piano più adatto a te</p>
            </div>
            <div className="col-sm-12 box-piani-jabsolute">
                <img src={img_jmyimprese} className="img-jmyimprese"></img>
            </div>
            <div className="col-sm-12">
                <div className="row box-piani-dim-boxes control-d">
                    <div className="col-sm-4 box-piani-box ">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">MyImprese Premium <br /><span className="box-piani-span">Il più acquistato dai Commercialisti</span></p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                <div className="col-sm-9">
                                        <span className="box-piani-row-text">Ricerca imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">2000</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Dettaglio imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">1000</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">6000</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Visura Camerale</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Bilanci Impresa</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report smart</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">10</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 box-piani-box ">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">MyImprese Gold<br /><span className="box-piani-span">Il più acquistato dai consulenti</span></p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Ricerca imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">1000</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Dettaglio imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">300</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Anagrafiche su file</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">4000</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Visura Camerale</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">5</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Bilanci Impresa</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">5</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report smart</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">5</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report azienda</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">5</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Report azienda approfondito</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">5</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 box-piani-box ">
                        <div className="row m-lr-7-5 box-bg-light box-piani-radius p-bottom-30">
                        <div className="col-sm-12 box-piani-boxes-header">
                                <p className="box-testimonial-title p-lr-30">MyImprese Start <br /><span className="box-piani-span"></span></p>

                            </div>
                            <div className="col-sm-12">
                                <p className=""></p>
                                <div className="row">
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Ricerca imprese</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">2000</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text">Dettaglio impresa</span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num">500</span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num"></span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num"></span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num"></span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num"></span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num"></span>
                                    </div>
                                    <div className="col-sm-9">
                                        <span className="box-piani-row-text"></span>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="box-piani-num"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PrezziPacchettiResponsive></PrezziPacchettiResponsive>
            </div>
            <div className="col-sm-12 p-top-50 p-bottom-50">
                <p className="box-piani-registrati-span">
                <span className="box-piani-registrati underline-link"
                onClick={handleRegister}
                >Registrati</span> adesso e ottieni 10 ricerche gratis!</p>
            </div>
        </div>
        <div className="row box-bg-light" id="chisiamo">
            <div className="col-sm-8">
                <p className="box-loghi-text p-top-100 p-bottom-100 px-custom">Da anni in Tecno Innovis sviluppiamo soluzioni al 
                    servizio dei professionisti. Grazie all’esperienza acquisita con myVisure abbiamo potuto conoscere i bisogni 
                    dei vari operatori e ampliare il ventaglio di strumenti utili per un mercato in continua evoluzione.Da questa consapevolezza 
                    e dalla collaborazione con i professionisti è nato MyImprese, un servizio di facile accesso e a costi contenuti, 
                    che offre anche alle piccole realtà informazioni e risorse di alta qualità, tipiche delle grandi aziende.</p>
            </div>
            <div className="col-sm-4">
                <div className="row">
                    <div className="col-sm-12 p-top-100 img-position p-sm-30">
                        <img src={img_logotecno} />
                    </div>
                    <div className="col-sm-6 p-top-30 img-dim"><img src={img_logomyimprese} /></div>
                    <div className="col-sm-6 p-top-30 img-dim"><img src={img_logomyvisure} /></div>
                    <div className="col-sm-12 p-top-30 img-position p-sm-50">
                        <Button className="box-loghi-button btn-primary"
                        onClick={handleAssistenza}
                        >Contattaci</Button>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="row box-footer-bg">
            <div className="col-sm-12">
                <div className="row box-footer-space">
                    <div className="col-sm-4">
                        <p className="box-footer-col1-text">MyImprese è un servizio</p>
                        <img src={img_logotecno} className="box-footer-logo-tecno space-sm" />

                    </div>
                    <div className="col-sm-4">
                            <p className="box-footer-link"><a href="/privacy-policy" className="box-footer-link-color">Privacy Policy</a></p>
                            <p className="box-footer-link"><a href="/termini-condizioni" className="box-footer-link-color">Termini e Condizioni</a></p>
                            <p className="box-footer-link"><a href="/assistenza" className="box-footer-link-color">Contattaci</a></p>
                    </div>
                    <div className="col-sm-4">
                        <p className="box-footer-text-col3">Gestiamo pagamenti sicuri sulle piattaforme</p>
                        <p className="image-right"><img src={img_logopaypal} className="box-footer-logo-tecno box-footer-logo_1" /></p>
                        <p className="image-right"><img src={img_logonexi} className="box-footer-logo-tecno box-footer-logo_2" /></p>
                    </div>

                </div>
            </div>
            
        </div>
        <div className="row box-copy">
            <div className="col-sm-12 p-top-20">
                <p className="t-align-center box-copy-text"><img src={img_logomyimpresawhite} className="box-copy-logo"/> - 2025</p>
            </div>
        </div>
    </div>
    </>
  );
};