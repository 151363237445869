import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch } from "redux/store";
import { tokenAPICALL } from "services/authService";
import { setJWTToken } from "redux/actions";
import { setError } from "redux/slices/auth";
import { Alert, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
//import { getLoginByUsername } from "services/ws_loginService";
import { passwordAPICALL, passwordRecuperaAPICALL } from "services/passwordService";
import { Link } from "react-router-dom";
import NavigationMenu from "template/NavigationMenu";
import FooterPage from "template/FooterPage";

const PrivacyPolicy: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rData = useSelector((state: RootState) => state.authToken);
    
    const[passwordNoUguali, setPasswordNoUguali] = useState(null);
    const[passwordModificata, setPasswordModificata] = useState(null);


    const searchParam = window.location.search;
    const params = new URLSearchParams(searchParam);
    const accessToken = params.get('service-access')
    
    const formik = useFormik({
        initialValues: {
            confirmpassword: '',
            password: ''
        },
        onSubmit: async (values) => {
            //setInvioEmail(true)
            if(values.password != values.confirmpassword){
                setPasswordNoUguali(false)
            }else{
                passwordRecuperaAPICALL(values.password, accessToken).then((response) => {
                    if(response != null && response.data != null && response.data.document){
                        setPasswordModificata(true)
                    }else{
                        setPasswordModificata(false)
                    }
                })
            }            
        },
    });

    const handlePageLogin = () => {

        navigate('/', { replace: true });

    }

    useEffect(() => {
        setPasswordNoUguali(true)
        setPasswordModificata(null)
        document.body.classList.toggle('bg-gradient-primary', true);
    }, [])
    return (
        <div className="">
            <NavigationMenu isLoading={false}/>
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-12">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        <div className="text-center">
                                            {/* <h1 className="h4 text-gray-900 mb-4">Privacy Policy</h1> */}
                                            <div>
                                            

<h3 style={{textAlign: "justify"}}><strong>INFORMATIVA PER IL TRATTAMENTO DEI DATI PERSONALI</strong></h3>
<p style={{textAlign: "justify"}}><strong>Informativa ai sensi e per gli effetti di cui all'art. 13 e ss. del Regolamento UE n. 679/2016 del 27 aprile 2016</strong>, (General Data Protection Regulation - G.D.P.R.):</p>

<ul style={{textAlign: "justify"}}>
 	<li><em>"relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati e che abroga la direttiva 95/46/CE (regolamento generale sulla protezione dei dati)"</em></li>
</ul>
<p style={{textAlign: "justify"}}>Ai sensi e per gli effetti di cui all'art. 13 del Regolamento UE n. 679/2016 vi informiamo di quanto segue. Con la registrazione ai nostri servizi, gli utenti comunicano volontariamente a Tecno Innovis S.r.l., Titolare del trattamento, i propri dati personali. I dati personali inviati saranno trattati nel rispetto dei principi di protezione dei dati personali stabiliti dal Regolamento UE n. 679/2016 e dalle altre norme vigenti in materia.</p>
<p style={{textAlign: "justify"}}>Il conferimento dei dati è facoltativo. La mancata comunicazione dei dati ritenuti obbligatori (identificati con il segno *) impedirà la corretta registrazione al portale, ed anche la possibilità di usufruire dei servizi riservati agli utenti registrati, e, in caso di acquisto, l'accettazione da parte di Tecno Innovis S.r.l. della proposta d'ordine e la sua effettiva esecuzione.</p>

<h3 style={{textAlign: "justify"}}>Titolare, Responsabile della Protezione dei dati e Responsabili del trattamento dei dati</h3>
<p style={{textAlign: "justify"}}>Il Titolare del trattamento è Tecno Innovis S.r.l. (di seguito "Titolare del trattamento") che potrà essere contattato mediante comunicazione scritta da inviarsi a:</p>

<ul style={{textAlign: "justify"}}>
 	<li>Tecno Innovis S.r.l., Via Ungaretti n. 1 - 87036 Rende (CS)</li>
</ul>
<p style={{textAlign: "justify"}}>oppure potrà essere contattata al seguente indirizzo di posta elettronica: privacypolicy@myimprese.it</p>

<h3 style={{textAlign: "justify"}}>Finalità e modalità del trattamento dei dati</h3>
<p style={{textAlign: "justify"}}>Con la registrazione o iscrizione al portale, i dati raccolti sono trattati al fine di consentire l'accesso ai prodotti, ai servizi e ai contenuti riservati agli utenti registrati.</p>
<p style={{textAlign: "justify"}}>Nel caso in cui l'utente abbia espresso il consenso al momento dell'attivazione del servizio, o lo esprima successivamente e fino alla revoca dello stesso, i suoi dati personali potranno essere trattati dal Titolare del trattamento per:</p>

<ol style={{textAlign: "justify"}}>
 	<li>consentire l'accesso a prodotti, servizi e contenuti del portale https://myimprese.it</li>
 	<li>invio, se gli utenti abbiano espresso il consenso al momento della registrazione, o lo esprimano successivamente, e fino alla revoca dello stesso, di comunicazioni di carattere commerciale su prodotti e/o servizi propri o di società terze, anche con modalità automatizzate, per finalità di vendita diretta, nonché per l'invio di ricerche di mercato e per la verifica del grado di soddisfazione degli utenti</li>
 	<li>effettuare, se gli utenti abbiano espresso il consenso al momento della registrazione, o lo esprimano successivamente, e fino alla revoca dello stesso, anche mediante strumenti elettronici, attività di analisi di specifici comportamenti e abitudini di consumo, in modo da migliorare i servizi forniti e indirizzare le proposte commerciali di interesse dell'utente, offrendo anche a terzi i dati aggregati di riepilogo delle aperture e dei click ottenuti attraverso un sistema di tracciatura mediante "cookie".</li>
 	<li>comunicare a terze parti i dati degli utenti, nel caso in cui gli stessi abbiano espresso il consenso al momento della registrazione, o lo esprimano successivamente, e fino alla revoca dello stesso, per l'invio di comunicazioni di carattere commerciale su prodotti e servizi di società terze, anche con modalità automatizzate, per finalità di vendita diretta, nonché per l'invio di ricerche di mercato</li>
 	<li>Il Titolare del trattamento inoltre, utilizzerà, i dati personali per finalità amministrative e contabili e per l'esecuzione degli obblighi contrattuali nei confronti degli utenti che facciano parte della propria clientela.</li>
</ol>
<p style={{textAlign: "justify"}}>I dati personali inviati mediante la registrazione a prodotti e servizi possono essere trattati dal Titolare del trattamento con strumenti automatizzati. Si conferma la possibilità per il Titolare del trattamento di trattare i dati in oggetto in forma aggregata, nel rispetto delle misure prescritte dall'Autorità Garante e in virtù dello specifico esonero dal consenso previsto dalla medesima, per analisi ed elaborazioni elettroniche (es.: raggruppamenti della clientela in categorie omogenee per livelli di servizi, consumi, spesa, ecc.) volte a controllare e monitorare periodicamente lo sviluppo e l'andamento economico delle attività di Tecno Innovis S.r.l., per definire i relativi processi industriali e commerciali, volti a migliorare i servizi, nonché a progettare e realizzare le campagne di comunicazione commerciale.</p>
<p style={{textAlign: "justify"}}>Specifiche misure di sicurezza sono osservate da Titolare del trattamento per prevenire:</p>

<ul style={{textAlign: "justify"}}>
 	<li>la perdita dei dati,</li>
 	<li>evitare usi illeciti, o non corretti</li>
 	<li>evitare accessi non autorizzati</li>
</ul>
<p style={{textAlign: "justify"}}>I dati personali sono registrati e custoditi su database elettronici situati in Italia, in Paesi appartenenti allo Spazio Economico Europeo (SEE) e in Paesi terzi che garantiscono un adeguato livello di protezione dei dati.</p>
<p style={{textAlign: "justify"}}>Si specifica che il trattamento dei dati personali forniti dagli utenti potrà essere effettuato anche da società, enti o consorzi, nominati responsabili del trattamento ai sensi dell'art. 28 del Regolamento UE citato, che, per conto di Tecno Innovis S.r.l., forniscono specifici servizi elaborativi, o attività connesse, strumentali o di supporto.</p>
<p style={{textAlign: "justify"}}>I dati personali forniti dagli utenti potranno essere, inoltre, comunicati:</p>

<ul style={{textAlign: "justify"}}>
 	<li>a società partecipate o collegate;</li>
 	<li>a soggetti ai quali la facoltà di accedere ai dati personali sia riconosciuta da disposizioni di legge, da regolamenti o dalla normativa comunitaria;</li>
 	<li>a soggetti nei confronti dei quali la comunicazione sia prevista da norme di legge o di regolamento, ovvero da soggetti pubblici per lo svolgimento delle loro funzioni istituzionali.</li>
</ul>
<p style={{textAlign: "justify"}}>Il periodo di conservazione dei dati personali di cui sopra è in linea con le previsioni normative vigenti.</p>

<h3 style={{textAlign: "justify"}}>Diritti dell'interessato</h3>
<p style={{textAlign: "justify"}}>L' interessato ha il diritto di chiedere a Tecno Innovis S.r.l.:</p>

<ul style={{textAlign: "justify"}}>
 	<li>l'accesso ai dati personali che lo riguardano, nonché la rettifica, la cancellazione, la limitazione, l'opposizione al loro trattamento, se effettuato in modo automatizzato sulla base del consenso o per l'esecuzione del contratto</li>
 	<li>revocare il consenso in qualsiasi momento senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca</li>
 	<li>proporre reclamo all'Autorità Garante per il trattamento dei dati personali, ai sensi dell'art. 77 del Regolamento UE 679/2016.</li>
</ul>
<h3 style={{textAlign: "justify"}}><strong>INFORMATIVA COOKIES</strong></h3>
<p style={{textAlign: "justify"}}>Nel portale myimprese.it vengono raccolte informazioni che possono riguardare l'utente, le sue preferenze, o il dispositivo di accesso a Internet (es.: computer, tablet o cellulare) e vengono utilizzate principalmente per adeguare il funzionamento del sito alle aspettative dell'utente, offrendo una migliore esperienza di navigazione e per inviare messaggi promozionali personalizzati.</p>
<p style={{textAlign: "justify"}}>Inoltre, proseguendo con la navigazione, l'utente acconsente in automatico all'uso dei cookies in conformità alla presente informativa "Cookie policy".</p>
<p style={{textAlign: "justify"}}>Di seguito sono elencati i vari tipi di cookies utilizzati, fermo quanto precede, per il portale web e altri servizi on line utilizziamo le seguenti categorie di cookies:</p>

<h4 style={{textAlign: "justify"}}>a) Cookies indispensabili</h4>
<p style={{textAlign: "justify"}}>Questi cookie sono necessari al corretto funzionamento del nostro portale web e per utilizzare, migliorare le varie funzioni e servizi richiesti. Senza questi cookie non sarebbe possibile gestire e automatizzare, per esempio:</p>

<ul style={{textAlign: "justify"}}>
 	<li>autenticazione dell'utente</li>
 	<li>accesso all'area riservata del portale</li>
 	<li>consultare il contenuto del carrello per gli acquisti on line</li>
 	<li>fatturazione elettronica</li>
 	<li>realizzazione del processo di acquisto di un ordine</li>
</ul>
<h4 style={{textAlign: "justify"}}>b) Cookies di analisi</h4>
<p style={{textAlign: "justify"}}>Questi cookies permettono di perfezionare il nostro portale web monitorando ed analizzando il comportamento degli utenti, creando dei loro profili al fine di apportare miglioramenti e di facilitare la navigazione.</p>
<p style={{textAlign: "justify"}}>I cookies di analisi aiutano a tener traccia di quali sono le pagine visitate frequentemente, quali sono le preferenze dell'utente, quali prodotti sono già stati visualizzati per evitare che vengano riproposti, efficacia della pubblicità presente sul portale web, se e quali difficoltà l'utente incontra nell'utilizzo del sito.</p>
<p style={{textAlign: "justify"}}>Per analizzare le modalità di utilizzo del portale web si avvale anche del servizio di analisi web "Google Analytics" fornito da Google (https://www.google.com/intl/it_it/analytics/learn/privacy.html)</p>
<p style={{textAlign: "justify"}}>Ulteriori informazioni in merito al servizio Google Analytics e alle procedure da seguire per disabilitare i cookies possono essere trovate i seguenti collegamenti:</p>
<p style={{textAlign: "justify"}}>http://www.google.com/intl/en/analytics/privacyoverview.html</p>
<p style={{textAlign: "justify"}}>https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</p>
<p style={{textAlign: "justify"}}>Tali dati non permettono di identificare personalmente l'utente: tutti i dati raccolti sono aggregati e, in quanto tali, anonimi.</p>

<h4 style={{textAlign: "justify"}}>c) Cookies pubblicitari e di pubblicità comportamentale</h4>
<p style={{textAlign: "justify"}}>Questi cookies sono utilizzati da Tecno Innovis S.r.l. per raccogliere dati sulle abitudini di navigazione e le preferenze dell'utente al fine di sviluppare un profilo specifico per personalizzazioni dell'offerta commerciale, per il compimento di ricerche di mercato o di vendita a distanza. Nei servizi online a pagamento questi cookies sono un prezioso strumento per consentire a Tecno Innovis S.r.l. il continuo miglioramento del prodotto, l'offerta di servizi più efficienti e con caratteristiche sempre più prossime alle reali esigenze dell'utente.</p>
<p style={{textAlign: "justify"}}>Il rifiuto a l'utilizzo di questa categoria di cookies potrà essere esercitata dall'utente utilizzando lo strumento di opt-out previsto nella pagina delle impostazioni del proprio browser, oppure in base a quanto di seguito specificato.</p>

<h4 style={{textAlign: "justify"}}>d) Cookies di terze parti e Social media cookie</h4>
<p style={{textAlign: "justify"}}>Il portale web può consentire l'utilizzo di cookies di terze parti che potrebbero raccogliere informazioni sugli spostamenti dell'utente in Internet e, in particolare, nell'ambito dei nostri siti web.</p>
<p style={{textAlign: "justify"}}>Inoltre, alcune pagine sul nostro sito web sono caratterizzate dalla presenza dei cosiddetti. "plugin social", i quali permettono di condividere i nostri contenuti su social media network come Facebook, Twitter, Youtube.</p>
<p style={{textAlign: "justify"}}>I cookies di terze parti non sono sotto il nostro controllo e, pertanto, per ulteriori informazioni su come la terza parte utilizza i cookies, si consiglia di visitare il sito web della terza parte di cui qui di seguito si riportano gli indirizzi web ove presenti le diverse informative e modalità di gestione dei cookies terze parti:</p>

<ul style={{textAlign: "justify"}}>
 	<li>Facebook:
<ul>
 	<li>Informativa: https://www.facebook.com/help/cookies/</li>
 	<li>Configurazioni: accedere al proprio account, sezione privacy</li>
</ul>
</li>
 	<li>Twitter:
<ul>
 	<li>Informativa: https://support.twitter.com/articles/20170514</li>
 	<li>Configurazioni: https://twitter.com/settings/security</li>
</ul>
</li>
 	<li>LinkedIn:
<ul>
 	<li>Informativa: https://www.linkedin.com/legal/cookie-policy</li>
 	<li>Configurazioni: https://www.linkedin.com/settings/</li>
</ul>
</li>
 	<li>Youtube:
<ul>
 	<li>http://www.google.com/policies/privacy/</li>
</ul>
</li>
 	<li>PayPal
<ul>
 	<li>Informativa: https://www.paypal.com/us/webapps/mpp/ua/privacy-full</li>
</ul>
</li>
<li>Nexi
<ul>
 	<li>Informativa: https://www.nexigroup.com/it/cookies/</li>
</ul>
</li>
</ul>
<h3 style={{textAlign: "justify"}}>Rinuncia ai cookies</h3>
<p style={{textAlign: "justify"}}>L'utente può rifiutare di accettare tutti o alcuni dei cookies utilizzati su questo sito web nelle seguenti modalità:</p>

<ul style={{textAlign: "justify"}}>
 	<li><strong>Gestione dei cookies utilizzati per finalità di marketing</strong>
L'utente può configurare le proprie scelte circa l'utilizzo o meno dei nostri cookies per finalità di marketing attraverso la funzionalità di "Gestione Consenso Cookie"
La scelta di disabilitare l'uso dei nostri cookies di profilazione impedirà la successiva memorizzare di ulteriori cookies da parte nostra, mentre i cookies precedentemente installati sul dispositivo dell'utente continueranno ad essere utilizzati sul dispositivo dell'utente.
L'utente se vuole cancellare tutti i cookies presenti sul suo dispositivo, potrà farlo semplicemente selezionando, dal proprio browser, le impostazioni che consentono di rifiutarli.
Ogni browser ha specifiche istruzioni di configurazione. Ulteriori informazioni in merito alle procedure da seguire per disabilitare i cookies possono essere trovati sul sito internet del fornitore del browser.
Google Chrome: https://support.google.com/chrome/answer/95647?hl=it
Mozilla Firefox: http://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie
Internet Explorer: http://windows.microsoft.com/it-it/windows7/block-enable-or-allow-cookies
Apple Safari: http://support.apple.com/kb/PH5049</li>
 	<li><strong>Gestione di Google Analytics</strong>
Per disabilitare soltanto l'uso dei cookies di Google Analytics l'utente può utilizzare il componente aggiuntivo messo a disposizione da Google al seguente collegamento seguendo la procedura di opt-out indicata da Google https://tools.google.com/dlpage/gaoptout?hl=it</li>
 	<li><strong>Gestione cookies di Targeting ed Advertising di terze parti</strong>
Per maggiori informazioni e/o per disabilitare i cookies di Targeting ed Advertising, l'utente à invece pregato di visitare il seguente sito web: http://www.youronlinechoices.com/it
Fermo restando quanto sopra, si rileva che disabilitando i cookies sarà ancora possibile utilizzare alcune parti dei nostri siti web, ma alcuni servizi potrebbero non essere utilizzabili.</li>
</ul>
<p style={{textAlign: "justify"}}>I dati raccolti con i cookies saranno conservati a seconda della loro natura:</p>

<ul style={{textAlign: "justify"}}>
 	<li style={{textAlign: "left"}}>i cookies di sessione scadono quando l'utente chiude il browser</li>
 	<li style={{textAlign: "left"}}>i cookies persistenti hanno date di scadenza tipiche che, a meno di particolari eccezioni, non superano i 20 giorni</li>
</ul>
<p style={{textAlign: "justify"}}>Tali dati saranno trattati esclusivamente nell'ambito delle finalità sopraddette e potranno essere comunicati a società controllate, controllanti, affiliate o comunque collegate.</p>
<p style={{textAlign: "justify"}}>Nel rispetto delle misure minime di sicurezza, i dati dell'utente potranno essere comunicati a soggetti di pubblica sicurezza e ad altri soggetti pubblici e privati per l'adempimento di obblighi previsti dalla legge anche di natura fiscale, amministrativa, finanziaria e simili. In nessun caso i dati saranno diffusi.</p>
<p style={{textAlign: "justify"}}>Nel rispetto dei principi di proporzionalità e necessità, i dati non saranno conservati per periodi più lunghi rispetto a quelli indispensabili alla realizzazione delle finalità sopra indicate e, dunque, al servizio offerto o alle specifiche norme di legge.</p>

<form id="form_cookie_policy" action="http://privacymgmt.wki.it/change_cookie_settings.php" method="post"></form>
<p style={{textAlign: "justify"}}><strong>FINALITA' DEL SERVIZIO</strong></p>
<p style={{textAlign: "justify"}}>L'utente che decide di registrarsi al servizio, è consapevole del fatto che i dati estratti e le informazioni dalle banche dati dei fornitori, sono di competenza e responsabiltà dei fornitori stessi escludendo di fatto la Tecno Innovis Srl da eventuali responsabilità su veridicità delle informazioni e/o mancati aggiornamenti.</p>
<p style={{textAlign: "justify"}}>Per maggiori informazioni e dettagli in merito a termini e condizioni si rimanda all’apposita sezione <a className="color-blue" href="https://myimprese.it/condizioni-utilizzo/">Condizioni di Utilizzo</a></p>
<p style={{textAlign: "justify"}}></p>
<p style={{textAlign: "justify"}}>In ogni momento l'utente, dal proprio account potrà esercitare i suoi diritti di accesso nei confronti del Titolare del trattamento, ai sensi dell'articolo 15 del Regolamento UE 2016/679, cioè potrà ottenere la conferma dell'esistenza dei suoi dati personali e chiederne la loro comunicazione in forma intelligibile tramite email. Avrà diritto di ottenere:</p>

<ul style={{textAlign: "justify"}}>
 	<li style={{textAlign: "left"}}>aggiornamento</li>
 	<li style={{textAlign: "left"}}>rettifica</li>
 	<li style={{textAlign: "left"}}>integrazione</li>
 	<li style={{textAlign: "left"}}>cancellazione dei dati</li>
 	<li style={{textAlign: "left"}}>limitazione del trattamento.</li>
</ul>
<p style={{textAlign: "justify"}}>L'utente avrò diritto di opporsi, in tutto o in parte, per motivi legittimi, al trattamento dei suoi dati personali, sebbene pertinenti allo scopo della raccolta, nonché all'invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale, rivolgendosi al Titolare del trattamento.</p>
<p style={{textAlign: "justify"}}>Avrà inoltre il diritto di proporre reclamo all'Autorità Garante della Privacy.</p>
<p style={{textAlign: "justify"}}>Il Titolare del Trattamento Tecno Innovis S.r.l., Via Ungaretti n. 1 - 87036 Rende (CS)</p>

</div>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <FooterPage />

        </div>
        
    );
};

export default PrivacyPolicy;


