import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from "./login";
import Register from "./register";
import AuthenticatedRoute from "components/auth";
import { Dashboard } from "components/dashboard";
import { FileUpload } from "components/upload";
import { NotFound } from "./404";
import { Ws_Prodotti, Ws_Log_Richieste, Ws_Login, Ws_Addebiti, Piano_Abbonamento, Piano_Estrazioni, Tipologie_Abbonamento, Ws_Ticket, Archivio, Ws_Anagrafica_Prodotto, Elenco_Imprese_Esteso_Estrazione, Elenco_Imprese, RicercaUnicaCompleta, ListaRicerche, CambioPassword, Profilo, Dati_Utente, Shop} from "components";
import { Elenco_Imprese_Esteso } from "components/elenco_imprese_esteso";
import { Elenco_Imprese_Esteso_Grafici } from "components/elenco_imprese_grafici";
import Documenti from "components/documenti";
import { RicercaImpreseCompleta } from "components/ricerca_imprese";
import ResetPassword from "./reset-password";
import RecuperaPassword from "./recupera-password";
import { Assistenza } from "components/assistenza";
import { AssistenzaUtente } from "./assistenza";
import { DettaglioAzienda } from "components/ricerca_unica/dettaglio";
import { Ordini } from "components/ordini";
import Homepage from "./homepage";
import { HomePagePublic } from "./home_public";
import PrivacyPolicy from "./privacy-policy";
import TerminiCondizioni from "./termini-condizioni";

const Pages: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePagePublic />}>
        </Route>
        {/* <Route path="/home-public" element={<HomePagePublic />}>
        </Route> */}
        <Route path="/accedi" element={<Login />}>
        </Route>
        <Route path="/registrazione" element={<Register />}>
        </Route>
        <Route path="/reset-password" element={<ResetPassword />}>
        </Route>
        <Route path="/assistenza" element={<AssistenzaUtente />}></Route>
        <Route path="/recupera-password" element={<RecuperaPassword />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/termini-condizioni" element={<TerminiCondizioni />}></Route>
        <Route path="/dashboard" element={<AuthenticatedRoute element={<Dashboard />} />}></Route>
        <Route path="/ricerca_imprese" element={<AuthenticatedRoute element={<RicercaImpreseCompleta />} />}></Route>
        <Route path="/ricerca_unica" element={<AuthenticatedRoute element={<RicercaUnicaCompleta />} />}></Route>
        <Route path="/ricerca_unica/:parametro" element={<AuthenticatedRoute element={<RicercaUnicaCompleta />} />}></Route>
        <Route path="/ricerca_unica/dettaglio/:id" element={<AuthenticatedRoute element={<DettaglioAzienda />} />}></Route>
        <Route path="/upload" element={<AuthenticatedRoute element={<FileUpload />} />}></Route>
        <Route path="/ws_prodotti" element={<AuthenticatedRoute element={<Ws_Prodotti />} />}></Route>
        <Route path="/ws_log_richieste" element={<AuthenticatedRoute element={<Ws_Log_Richieste />} />}></Route>
        <Route path="/ws_login" element={<AuthenticatedRoute element={<Ws_Login />} />}></Route>
        <Route path="/ws_addebiti" element={<AuthenticatedRoute element={<Ws_Addebiti />} />}></Route>
        <Route path="/elenco_imprese_esteso" element={<AuthenticatedRoute element={<Elenco_Imprese_Esteso />} />}></Route>
        <Route path="/elenco_imprese" element={<AuthenticatedRoute element={<Elenco_Imprese />} />}></Route>
        <Route path="/elenco_imprese_grafici" element={<AuthenticatedRoute element={<Elenco_Imprese_Esteso_Grafici />} />}></Route>
        <Route path="/piano_abbonamento" element={<AuthenticatedRoute element={<Piano_Abbonamento />} />}></Route>
        <Route path="/piano_estrazioni" element={<AuthenticatedRoute element={<Piano_Estrazioni />} />}></Route>
        <Route path="/tipologie_abbonamento" element={<AuthenticatedRoute element={<Tipologie_Abbonamento />} />}></Route>
        <Route path="/ws_ticket" element={<AuthenticatedRoute element={<Ws_Ticket />} />}></Route>
        <Route path="/archivio" element={<AuthenticatedRoute element={<Archivio />} />}></Route>
        <Route path="/ws_anagrafica_prodotto" element={<AuthenticatedRoute element={<Ws_Anagrafica_Prodotto />} />}></Route>
        <Route path="/elenco_imprese_esteso_estrazione" element={<AuthenticatedRoute element={<Elenco_Imprese_Esteso_Estrazione />} />}></Route>
        <Route path="/richieste_assistenza" element={<AuthenticatedRoute element={<Assistenza />} />}></Route>
        <Route path="/lista_ricerche" element={<AuthenticatedRoute element={<ListaRicerche />} />}></Route>
        <Route path="/cambio_password" element={<AuthenticatedRoute element={<CambioPassword />} />}></Route>
        <Route path="/profilo" element={<AuthenticatedRoute element={<Profilo />} />}></Route>
        <Route path="/shop" element={<AuthenticatedRoute element={<Shop />} />}></Route>
        <Route path="/shop/annulla/:id" element={<AuthenticatedRoute element={<Shop />} />}></Route>
        <Route path="/shop/esito/:id" element={<AuthenticatedRoute element={<Shop />} />}></Route>
        <Route path="/ordini" element={<AuthenticatedRoute element={<Ordini />} />}></Route>

        <Route path="/dati_utente" element={<AuthenticatedRoute element={<Dati_Utente />} />}></Route>
        {/* <Route path="/ricerca_unica/:idricerca?" element={<AuthenticatedRoute element={<RicercaUnicaCompleta />} />}></Route> */}
        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;

