import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { setError } from "redux/slices/auth";
import { addWs_Login, registration } from "services/ws_loginService";
import { Constant } from "template/Constant";
import md5 from 'crypto-js/md5';
import { sendMailRegistrazione } from "services/mailService";
import Layout from "template";
import LoadingSpinner from "components/LoadingSpinner";
import img_jmyimprese from "../asset/Intersect.png"
import img_logotecno from "../asset/TecnoinnovisLogo.png"
import img_logomyimprese from "../asset/Logomyimprese.png"
import img_logomyvisure from "../asset/logo_myvisure.png"
import img_logopaypal from "../asset/logo_pp.png"
import img_logonexi from "../asset/logo_n.png"
import img_logomyimpresawhite from "../asset/logo_mi_white.png"
import logo from "../asset/logo_myimprese.png"
import { useNavigate } from "react-router";
import NavigationMenu from "template/NavigationMenu";
import FooterPage from "template/FooterPage";
import { addPiano_Abbonamento } from "services/piano_abbonamentoService";
import { StrictModifiers } from "@popperjs/core";


const Register: React.FC = () => {

    const emailRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const pwdRef = useRef(null);
    const rpwdRef = useRef(null);
    const nomeRef = useRef(null);
    const cognomeRef = useRef(null);
    const nomeAziendaRef = useRef(null);
    const telRef = useRef(null);
    const pivaRef = useRef(null);

    const[passwordNoUguali, setPasswordNoUguali] = useState(null);
    const[registrato, setRegistrato] = useState(false);
    const[error, setError] = useState(false);
    const[messaggioErrore , setMessaggioErrore] = useState(undefined)

    const[accettaTermini , setAccettaTermini] = useState(false)


    function isValidEmail(email) {
        // Espressione regolare per la convalida di email
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      
        // Restituisce true se la stringa corrisponde all'espressione regolare
        return regex.test(email);
      }

    function isValidItalianID(id) {
        console.log(id.length)
        // Controllo lunghezza
        if (id.length !== 16 && id.length !== 11) {
            return false;
        }
      
        // // Controllo se la stringa è composta solo da numeri
        // const regex = /^[0-9]+$/;
        // if (!regex.test(id)) {
        //     return false;
        // }
      
        // Controllo partita IVA
        if (id.length === 11) {
            return isValidVAT(id);
        }
      
        // Controllo codice fiscale
        if (id.length === 16) {
            console.log("false___")
            return isValidFiscalCode(id);
        }
      
        return false;
    }
      
    // function isValidVAT(vatNumber) {
    //     // Algoritmo di controllo per partita IVA (algoritmo di Luhn modificato)
    //     if (vatNumber.length !== 11) {
    //         return false;
    //     }
      
    //     let sum = 0;
    //     for (let i = 0; i < 10; i++) {
    //         let digit = parseInt(vatNumber[i]);
    //         if (i % 2 === 0) {
    //             // Per le posizioni pari (0-based index), moltiplico per 2
    //             digit *= 2;
    //             // Se il risultato è maggiore di 9, sottraggo 9
    //             digit = digit > 9 ? digit - 9 : digit;
    //         }
    //         sum += digit;
    //     }
      
    //     // Calcolo del check digit
    //     let checkDigit = (10 - (sum % 10)) % 10;
    //     return checkDigit === parseInt(vatNumber[10]);
    // }

    function isValidVAT(vatNumber) {
        // Algoritmo di controllo per partita IVA (algoritmo di Luhn modificato)
        if (vatNumber.length !== 11) {
            return false;
        }
    
        let sum = 0;
        for (let i = 0; i < 10; i++) {
            let digit = parseInt(vatNumber[i]);
            if (i % 2 === 0) {
                // Moltiplico per 1 le posizioni dispari (0-based index)
                sum += digit;
            } else {
                // Moltiplico per 2 le posizioni pari (0-based index)
                digit *= 2;
                // Se il risultato è maggiore di 9, sottraggo 9
                digit = digit > 9 ? digit - 9 : digit;
                sum += digit;
            }
        }
    
        // Calcolo del check digit
        let checkDigit = (10 - (sum % 10)) % 10;
        return checkDigit === parseInt(vatNumber[10]);
    }
      
    function isValidFiscalCode(fiscalCodeInput:string) {
        let fiscalCode = fiscalCodeInput.toUpperCase()
        // Controllo lunghezza e formato base
        if (fiscalCode.length !== 16) {
            console.log("false 1")
            return false;
        }
      
        // Controllo caratteri (primi 6 lettere, poi numeri, poi lettera e numeri)
        const regex = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/;
        if (!regex.test(fiscalCode)) {
            console.log("false")
            return false;
        }
      
        // Tabelle di conversione per il controllo
        const evenMap = {
            '0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9,
            'A': 0, 'B': 1, 'C': 2, 'D': 3, 'E': 4, 'F': 5, 'G': 6, 'H': 7, 'I': 8, 'J': 9,
            'K': 10, 'L': 11, 'M': 12, 'N': 13, 'O': 14, 'P': 15, 'Q': 16, 'R': 17, 'S': 18,
            'T': 19, 'U': 20, 'V': 21, 'W': 22, 'X': 23, 'Y': 24, 'Z': 25
        };
      
        const oddMap = {
            '0': 1, '1': 0, '2': 5, '3': 7, '4': 9, '5': 13, '6': 15, '7': 17, '8': 19, '9': 21,
            'A': 1, 'B': 0, 'C': 5, 'D': 7, 'E': 9, 'F': 13, 'G': 15, 'H': 17, 'I': 19, 'J': 21,
            'K': 2, 'L': 4, 'M': 18, 'N': 20, 'O': 11, 'P': 3, 'Q': 6, 'R': 8, 'S': 12, 'T': 14,
            'U': 16, 'V': 10, 'W': 22, 'X': 25, 'Y': 24, 'Z': 23
        };
      
        let sum = 0;
        for (let i = 0; i < 15; i++) {
            const char = fiscalCode[i];
            sum += (i % 2 === 0) ? oddMap[char] : evenMap[char];
        }
      
        // Calcolo del carattere di controllo
        const controlChar = String.fromCharCode(65 + (sum % 26));
        console.log(controlChar)
        console.log(fiscalCode[15])
        return controlChar === fiscalCode[15];
    }

      function isValidPhoneNumberWithoutPlus39(phoneNumber) {
        // Espressione regolare per la convalida di numeri di telefono italiani senza +39
        const regex = /^((3[0-9]{2}|0[1-9]{2}|[1-9]{2}))( )?([0-9]{3})( )?([0-9]{4})$/;
      
        // Restituisce true se la stringa corrisponde all'espressione regolare
        return regex.test(phoneNumber);
      }

      function isValidPassword(password) {
        // Espressione regolare per la convalida della password
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
      
        // Restituisce true se la stringa corrisponde all'espressione regolare
        return regex.test(password);
      }

    const handleRegistra = () =>{

        setError(false)
        setIsLoading(true);
        setMessaggioErrore([])

            // let dati = {
            //     "username": emailRef.current.value,
            //     "nome_referente": nomeRef.current.value,
            //     "cognome_referente": cognomeRef.current.value,
            //     "nome_azienda": nomeAziendaRef.current.value,
            //     "telefono": telRef.current.value,
            //     "partita_iva": pivaRef.current.value,
            //     //"password": pwdRef.current.value,
            //     "email":emailRef.current.value
            // }

            type Dati = {
                username: any;
                nome_referente: any;
                cognome_referente: any;
                nome_azienda: any;
                telefono: any;
                partita_iva: any;
                email: any;
              };
              
              let dati: Dati = {
                username: emailRef.current.value,
                nome_referente: nomeRef.current.value,
                cognome_referente: cognomeRef.current.value,
                nome_azienda: nomeAziendaRef.current.value,
                telefono: telRef.current.value,
                partita_iva: pivaRef.current.value,
                email: emailRef.current.value,
              };
              
              // Converti in maiuscolo tutti i valori stringa, tranne 'email'
              dati = Object.fromEntries(
                Object.entries(dati).map(([key, value]) => [
                  key,
                  key === "email" || typeof value !== "string" ? value : value.toUpperCase(),
                ])
              ) as Dati;
              




            if(isValidEmail(emailRef.current.value) 
            && isValidItalianID(pivaRef.current.value) 
        && isValidPhoneNumberWithoutPlus39(telRef.current.value)
        && nomeRef.current.value.length >2 && cognomeRef.current.value.length >2 
        //&& nomeAziendaRef.current.value.length >2 
        && accettaTermini){

            registration(dati).then(async (response) => {
                console.log(response)
                if(response.status === 200){
                    let dati = {
                        "mail": emailRef.current.value
                    }
                    //sendMailRegistrazione(dati).then((response) =>{
                        
                        setRegistrato(true)
                        setIsLoading(false);

                        // const iValue={
                        //     id_utente:response.data.document,
                        //     id_abbonamento:'',
                        //     codice_abbonamento:"MYIMP_10" + String(response.data.document),
                        //     costo:'',
                        //     attivo:'',
                        //     numero_ricercaunica_massimo:10,
                        //     numero_ricercaunica_residuo:10,
                        //     numero_anagrafiche_massimo:'',
                        //     numero_anagrafiche_residuo:'',
                        //     numero_dettaglio_impresa:'',
                        //     numero_dettaglio_impresa_residuo:'',
                        //     nunero_bilanci_massimo:'',
                        //     nunero_bilanci_residuo:'',
                        //     nunero_visure_massimo:'',
                        //     nunero_visure_residuo:'',
                        //     nunero_protesti_massimo:'',
                        //     nunero_protesti_residuo:'',
                        //     nunero_catastali_massimo:'',nunero_catastali_residuo:'',
                        //     numero_reportsmart_massimo:'',numero_reportsmart_residuo:'',
                        //     numero_deepdossier_massimo:'',numero_deepdossier_residuo:'',
                        //     numero_executivereport_massimo:'',numero_executivereport_residuo:'',
                        //     numero_reportpersona_massimo:'',numero_reportpersona_residuo:'',
                        //     numero_reportpersonacompleto_massimo:'',numero_reportpersonacompleto_residuo:'',data_apertura:'',data_scadenza:''};

                        //     const response_abbo = await addPiano_Abbonamento(iValue);
                        //     if (response_abbo) {
                        //         console.log("ok abbonamneto")
                        //     }else{
                        //         console.log("errore")
                        //     }
    
                    //}
                    //)
                }
                if(response.status === 203){
                    let mess = []
                    mess.push("Controlla i dati inseriti perchè non è possibile registrarti al servizio")
                    setIsLoading(false);
                    setMessaggioErrore(mess)
                    setError(true)
                }

            })

        }else{
            let mess = []
            if(!isValidEmail(emailRef.current.value)) mess.push("devi inserire una email valida")
            if(!isValidItalianID(pivaRef.current.value) ) mess.push("devi inserire una partita iva o codice fiscale valido")
            if(!isValidPhoneNumberWithoutPlus39(telRef.current.value)) mess.push("devi inserire un numero telefonico valido")
            if(nomeRef.current.value.length < 3) mess.push("Nome deve essere valido")
            if(cognomeRef.current.value.length < 3) mess.push("Cognome deve essere valido")
            //if(nomeAziendaRef.current.value.length < 3) mess.push("Nome Azienda deve essere valido")
            if(!accettaTermini) mess.push("devi accettare Termini e Condizioni")


            setMessaggioErrore(mess)
            setIsLoading(false);
            setError(true)
        }
    }


    useEffect(() => {
        document.body.classList.toggle('bg-gradient-primary', true);
    }, [])
    function dispatch(arg0: any): void {
        throw new Error("Function not implemented.");
    }

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navigate = useNavigate();
  
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLoging = () => {
    navigate('/', { replace: true });
  }

  const handleAssistenza = () => {
    navigate('/assistenza', { replace: true });
  }

  const MenuItems = () => (

    <>
      <p onClick={()=>handleLoging()} className="font-menu cursor-pointer hover:text-blue-500 transition-colors">Home</p>
    </>
  );

  const handleCheckboxChange = (e) => {
    const { checked } = e.target; // Ottieni lo stato (checked) dal target dell'evento
    const errorDiv = document.getElementById("acceptTermsError");

    setAccettaTermini(checked)

    console.log(checked)
  
    if (!checked) {
      errorDiv.textContent = "Devi accettare le condizioni del servizio.";
      errorDiv.style.display = "block";
    } else {
      errorDiv.textContent = "";
      errorDiv.style.display = "none";
    }
  };

    return (
        <>
        <div className="">
        <NavigationMenu isLoading={isLoading} />

        {/* {isLoading ? <LoadingSpinner /> : isLoading}
        <div className="">
            <div className="nav-desktop row menu-basic align-items-center px-custom">
      
            <div className="col-sm-6">
                <img src={logo} alt="" className="logo-public" />
            </div>
            <div className="col-sm-6">
                <div className="d-flex justify-content-end gap-3">
                    <p  onClick={()=>handleLoging()} className="font-menu">Home</p>
                    <p  onClick={()=>handleAssistenza()} className="font-menu">Assistenza</p>
                </div>
            </div>
   
            </div>
        <div className="nav-mobile row menu-basic align-items-center px-custom relative paddind-lr-m-15 min-height-auto">
            <div className='row full-width'>
                <div className="col-sm-6 dim-custom-80">
                    <img src={logo} alt="Logo" className="logo-public" />
                </div>
                <div className="col-sm-6 dim-custom-20 flex text-center">
                    <i className="fa fa-bars icon-bar-sm" onClick={toggleMobileMenu}></i>
                </div>
            </div>
            {isMobileMenuOpen && (
            <div className="fixed inset-0 bg-white z-40 pt-20">
                <div className="flex flex-col items-center space-y-6">
                    <MenuItems />
                </div>
            </div>
            )}
        </div> */}



        <div className="card o-hidden border-0 shadow-lg">
            <div className="card-body p-0">
            <div className="row img-principal box-login-py-50">
            <div className="col-sm-4">
            <p className="style-text s1-image-text-reg s1-image-text-reg-center">
            Registrati al servizio
            </p>
            <p className="s1-text s2-image-text">
            Trova le aziende che ti interessano per area geografica, dimensione, fatturato, crea delle liste o acquisisci le informazioni che ti servono. In modo semplice e ad un prezzo accessibile anche per le piccole organizzazioni.
            </p>
            </div>
            
            <div className="col-sm-8">
            <div className="box-reg background-linear">
              <p className="box-login-title box-login-text">Registrati per usare il servizio</p>
              <form className="user-reg">
                                <div className="form-group">
                                    <input required type="email" className="form-control form-control-user" id="inputEmail"
                                        placeholder="EMAIL *" ref={emailRef}/>
                                </div>
                                {/* <div className="form-group row">
                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                        <input type="password" className="form-control form-control-user"
                                            id="inputPassword" placeholder="Password" ref={pwdRef}/>
                                    </div>
                                    <div className="col-sm-6">
                                        <input required type="password" className="form-control form-control-user"
                                            id="repeatPassword" placeholder="Ripeti Password" ref={rpwdRef}/>
                                    </div>
                                </div> */}
                                <div className="form-group row">
                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                        <input  required type="text" className="form-control form-control-user text-uppercase" id="nf"
                                            placeholder="Nome *" ref={nomeRef}/>
                                    </div>
                                    <div className="col-sm-6">
                                        <input required type="text" className="form-control form-control-user text-uppercase" id="cf"
                                            placeholder="Cognome *" ref={cognomeRef}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input required type="tect" className="form-control form-control-user text-uppercase" id="nomeazienda"
                                        placeholder="Nome Azienda" ref={nomeAziendaRef} />
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                        <input required type="text" className="form-control form-control-user text-uppercase" id="piv"
                                            placeholder="PARTITA IVA/ CODICE FISCALE *" ref={pivaRef}/>
                                    </div>
                                    <div className="col-sm-6">
                                        <input required type="text" className="form-control form-control-user" id="tel"
                                            placeholder="TELEFONO *" ref={telRef}/>
                                    </div>
                                </div>
                                <div className="form-check padding-t-5 padding-b-30">
  <input 
    type="checkbox" 
    name="acceptTerms" 
    id="acceptTerms" 
    className="form-check-input" 
    onChange={(e)=> handleCheckboxChange(e)}
  />
  <label className="form-check-label">
    Accetti le condizioni del servizio?{" "}  
    <a 
      href="/termini-condizioni" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{
        fontWeight: 'bold',
        color: '#ffffff',
        textDecoration: 'underline',
      }}
    >
    Leggi qui
    </a>
  </label>
</div>

                                <input type="info" onClick={handleRegistra}  className="btn-user btn-block btn btn-info btn btn-primary margin-t-50" value="Registrati">
                                    
                                </input>
                                <hr />
                                {/* <a href="index.html" className="btn btn-google btn-user btn-block">
                                    <i className="fab fa-google fa-fw"></i> Register with Google
                                </a>
                                <a href="index.html" className="btn btn-facebook btn-user btn-block">
                                    <i className="fab fa-facebook-f fa-fw"></i> Register with Facebook
                                </a> */}
                            </form>
                            { error &&
                                            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">
                                                {messaggioErrore.map((ms, i) =>{
                                                    return(
                                                        <ul>
                                                            <li>{ms}</li>
                                                        </ul>
                                                    )
                                                })}
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(false)}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert>
                                        }
                            {registrato &&
                                            <Alert variant={Constant.defaultAlertSuccess} className="alert-dismissible fade">Registrazione avvenuta con successo! Controlla la tua email
                                                {/* <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(false)}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button> */}
                                            </Alert>
                                        }
             
            </div>
            </div>
            
        </div>
        {/* <div className="row box-footer-bg">
            <div className="col-sm-12">
                <div className="row box-footer-space">
                    <div className="col-sm-4">
                        <p className="box-footer-col1-text">MyImprese è un servizio</p>
                        <img src={img_logotecno} className="box-footer-logo-tecno space-sm" />

                    </div>
                    <div className="col-sm-4">
                            <p className="box-footer-link"><a href="/privacy-policy" className="box-footer-link-color">Privacy Policy</a></p>
                            <p className="box-footer-link"><a href="/termini-condizioni" className="box-footer-link-color">Termini e Condizioni</a></p>
                            <p className="box-footer-link"><a href="/assistenza" className="box-footer-link-color">Contattaci</a></p>
                    </div>
                    <div className="col-sm-4">
                        <p className="box-footer-text-col3">Gestiamo pagamenti sicuri sulle piattaforme</p>
                        <p className="image-right"><img src={img_logopaypal} className="box-footer-logo-tecno box-footer-logo_1" /></p>
                        <p className="image-right"><img src={img_logonexi} className="box-footer-logo-tecno box-footer-logo_2" /></p>
                    </div>

                </div>
            </div>
            
        </div>
        <div className="row box-copy">
            <div className="col-sm-12 p-top-20">
                <p className="t-align-center box-copy-text"><img src={img_logomyimpresawhite} className="box-copy-logo"/> - 2024</p>
            </div>
        </div> */}
        <FooterPage />
                {/* <div className="row">
                    <div className="col-lg-12">
                        <div className="p-5">
                            <div className="text-center">
                                <h1 className="h4 text-gray-900 mb-4">Registrati al Servizio</h1>
                            </div>
                            <form className="user">
                                <div className="form-group">
                                    <input required type="email" className="form-control form-control-user" id="inputEmail"
                                        placeholder="Email" ref={emailRef}/>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                        <input  required type="text" className="form-control form-control-user" id="nf"
                                            placeholder="Nome" ref={nomeRef}/>
                                    </div>
                                    <div className="col-sm-6">
                                        <input required type="text" className="form-control form-control-user" id="cf"
                                            placeholder="Cognome" ref={cognomeRef}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input required type="tect" className="form-control form-control-user" id="nomeazienda"
                                        placeholder="Nome Azienda" ref={nomeAziendaRef} />
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                        <input required type="text" className="form-control form-control-user" id="piv"
                                            placeholder="Partita Iva / Codice Fiscale" ref={pivaRef}/>
                                    </div>
                                    <div className="col-sm-6">
                                        <input required type="text" className="form-control form-control-user" id="tel"
                                            placeholder="telefono" ref={telRef}/>
                                    </div>
                                </div>
                                <input type="info" onClick={handleRegistra}  className="btn-user btn-block btn btn-info btn btn-primary" value="Registrati">
                                    
                                </input>
                                <hr />
                            </form>
                            <hr />
                            { error &&
                                            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">
                                                {messaggioErrore.map((ms, i) =>{
                                                    return(
                                                        <ul>
                                                            <li>{ms}</li>
                                                        </ul>
                                                    )
                                                })}
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(false)}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert>
                                        }
                            {registrato &&
                                            <Alert variant={Constant.defaultAlertSuccess} className="alert-dismissible fade">Registrazione avvenuta con successo! Controlla la tua email
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(false)}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert>
                                        }

                                        
                            <div className="text-center">
                                <a className="small" href="/reset-password">Password dimenticata?</a>
                            </div>
                            <div className="text-center">
                                <a className="small" href="/">Hai già un account? Accedi!</a>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>

    </div>
    </>
    );
};

export default Register;

