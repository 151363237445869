import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Button, Row } from "react-bootstrap";
import { useAppDispatch } from "redux/store";
import Layout from "template";
import LoadingSpinner from "../LoadingSpinner";
import { parseJwtToken } from "services/tokenService";
import { getOneDati_UtentebyidUtente } from "services/dati_utenteService";
import { getAllTipologie_Abbonamento } from "services/tipologie_abbonamentoService";
import { aggiornaOrdine, aggiornaOrdineId, cancellaOrdineId, creaPagamento, getOrdine, getOrdineTest, getPacchettiOrdine } from "services/pagamentoService";
import moment from "moment";
import ConfirmationOrdine from "template/ConfirmationOrdine";


import { useParams } from 'react-router-dom';
import ConfirmationAnnullaOrdine from "template/ConfirmationAnnullaOrdine";
import ConfirmationAggiuntaPacchettoErrore from "template/ConfirmationAggiuntaPacchettoErrore";
import { decodicaEsitoPagamento } from "services/shopService";
import ConfirmationEsitoOrdine from "template/ConfirmationEsitoOrdine";
import { sendMailOrderFailed, sendMailOrderSuccess } from "services/mailService";
import ConfirmationInfoCrediti from "template/ConfirmationInfoCrediti";
import ConfirmationInfoAcquisto from "template/ConfirmationInfoAcquisto";

interface Params extends Record<string, string>{
  idpk: string;
}

export const Shop: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [datiShop, setDatiShop] = useState(undefined);
  const [datiShopAggiuntive, setDatiShopAggiountive] = useState(undefined);
  const [datiPacchetto, setDatiPacchetto] = useState(undefined);
  const [listaDatiPacchetto, setListaDatiPacchetto] = useState([]);
  const [sommaCosto, setSommaCosto] = useState(0);
  const [sommaCostoIva, setSommaCostoIva] = useState(0);
  const [iva, setIva] = useState(0);
  const [showOrdine, setShowOrdine] = useState(false);
  const [showCarrello, setShowCarrello] = useState(false);
  const [showAnnullaOrdine, setShowAnnullaOrdine] = useState(false);
  const [datiPagamento, setDatiPagamento] = useState(undefined);
  const [datiRiepilogoPagamento, setDatiRiepilogoPagamento] = useState(undefined);
  const [tipoPagamento, setTipoPagamento] = useState(undefined);
  const [idOrdine, setIdOrdine] = useState(undefined);
  const [listaIdpk, setListaIdpk] = useState(undefined);
  const [showControlloPacchetto, setShowControlloPacchetto] = useState(false);

  const [showEsitoOrdine, setShowEsitoOrdine] = useState(false);
  const [datiEsitoOrdine, setDatiEsitoOrdine] = useState(undefined);
  const [messaggioPacchetto, setMessaggioPacchetto] = useState("");

  const [showInfoCrediti, setShowInfoCrediti] = useState(false);
  const [showInfoAcquisto, setShowInfoAcquisto] = useState(false);

  const location = useLocation();

  const { idpk } = useParams<Params>();

  const decode = decodeURIComponent(idpk)

  const [count, setCount] = useState(0);
  const [esitoOrdine, setEsitoOrdine] = useState(undefined);

  const token = localStorage.getItem('token');
  const jwtToken = parseJwtToken(token);




  const parseIds = (queryString: string) => {
    const ids: string[] = [];
    let currentId: string = "";
  
    for (let i = 0; i < queryString.length; i++) {
      const char = queryString[i];
  
      if (char === "&") {
        // Nuovo ID trovato
        ids.push(currentId);
        currentId = "";
      } else if (char !== "=") {
        // Aggiungi il carattere all'ID corrente
        currentId += char;
      }
    }
  
    // Aggiungi l'ultimo ID
    if (currentId) {
      ids.push(currentId);
    }
  
    return ids;
  }

  const  parseIds_reg = (queryString: string) => {
    const regex = /idpk=(\d+)/g;
    const matches = regex.exec(queryString)?.map((match) => match[1]);
  
    return matches ?? [];
  }

  function extractIdpkParams(url: string): string[] {
    const params = new URLSearchParams(url.split('?')[1]);
    const idpkParams = params.getAll('idpk');
    return idpkParams.map(decodeURIComponent);
  }

  let listapacchetto = []

  const handleServerDelete = async () => {}

  let numero_imprese = 0;
  const getDatiShop = () => {
    
    setIsLoading(true);
    if(jwtToken){
        let idUtente = jwtToken.data.id;

        if(datiShop == null){
          getAllTipologie_Abbonamento(0, 1000).then((response) => {
                if (response && response.data && response.data.document.records) {
                  console.log(response);
                  
                setDatiShop(response.data.document.records);
                setDatiShopAggiountive(response.data.document.records);
                setIsLoading(false);

                }else{
                    setIsLoading(false);

                }
              })
        }else{
            setIsLoading(false);
        }
        
    }else{
        setIsLoading(false);
    }
  }

  useEffect(() => {
    
    console.log(location.pathname + location.search)

    if(datiShop == null)getDatiShop()   
    // getOrdineTest().then((response) => {
    //     console.log(response);
    // })
    if(datiShop != null){

      if (location.pathname.includes("annulla")) {
        loadPacchettiPagamento(listaDatiPacchetto, datiShop)
        const urlcomponent = location.pathname.split("/")
        let idordineurl = urlcomponent[3]
        cancellaOrdineId(idordineurl)
      }
  
      if (location.pathname.includes("esito")) {
  
        const url = location.pathname + location.search;
        let listaIdpktmp = extractIdpkParams(url)
        setListaIdpk(listaIdpktmp)
        console.log(extractIdpkParams(url))
  
        decodicaEsitoPagamento(location.search).then((result) =>{
          console.log("-----------------------------")
          console.log(result)
          console.log("-------------------------------")
          let esito = "KO"
          let messaggio = result
          let stato = 5
          if(result === "OK") esito = "OK"
          if(result === "OK") messaggio = "Ordine concluso con successo! A breve sarà possibile utilizzare i servizi"
          if(result === "OK") stato = 3
          const urlParams = new URLSearchParams(window.location.search);
          const ordine = urlParams.get('ordine');
          // if(stato == 3){
          //   let lista = []
          //   getPacchettiOrdine(ordine).then((result) =>{
  
              
  
          //     result.data.map((pc, i) => {
          //       lista.push(pc.nomeAbbonamento)
          //     })
  
          //     let dati_email = {
          //       "mail": jwtToken.data.email,
          //       "idrif": ordine,
          //       "listapacchetto": lista
          //     }
              
          //     console.log("lisa pacchetti")
          //     console.log(dati_email)
  
          //     sendMailOrderSuccess(dati_email).then((result)=>{
          //       console.log("**************invio*****************")
          //       navigate("/ordini/", {replace: true})
          //     })
          //   })
            
          // }
          
          //sif(esito === "KO") setShowAnnullaOrdine(true)
  
          
          aggiornaOrdine(ordine, stato, listaIdpktmp).then((result) =>{
            
            if(stato == 3){
              let lista = []
              getPacchettiOrdine(ordine).then((result) =>{
    
                
    
                result.data.map((pc, i) => {
                  lista.push(pc.nomeAbbonamento)
                })
    
                let dati_email = {
                  "mail": jwtToken.data.email,
                  "idrif": ordine,
                  "listapacchetto": lista
                }
                
                console.log("lisa pacchetti")
                console.log(dati_email)
    
                sendMailOrderSuccess(dati_email).then((result)=>{
                  console.log("**************invio*****************")
                  navigate("/ordini/", {replace: true})
                })
              })
              
            }
            if(stato == 2 || stato == 5){
              let dati_email = {
                "mail": jwtToken.data.email,
                "idrif": ordine
              }
              sendMailOrderFailed(dati_email).then((result)=>{
                console.log(result)
                //navigate("/ordini/", {replace: true})
              })
            }
            setEsitoOrdine(result)
            let dati = {
              "esito": esito,
              "messaggio": messaggio
            }
    
            let stato_ordine = {
              "stato_ordine": stato
            }
  
            
            setDatiEsitoOrdine(dati)
            setShowEsitoOrdine(true)
          })
          
          
        })
        
      } else {
        setEsitoOrdine("")
      }

    }
    

  }, [datiShop]);


  const loadPacchettiPagamento = (listaDatiPacchetto, datiShop) =>{

    const url = location.pathname + location.search; // Combine pathname and search
    console.log(extractIdpkParams(url))
    let listaIdpktmp = extractIdpkParams(url)
    setListaIdpk(listaIdpktmp)
    
    if((listaDatiPacchetto != null && listaDatiPacchetto.length > 0) || count === 0){
      let listaIdReturn = []

      let somma = sommaCostoIva;
      let iva = 0
      let sommaiva = 0
      let sommaTotale = 0;
      listaIdpktmp.forEach(id =>{
        if(datiShop != null){
          datiShop.map((dati, x) =>{
            if(id === dati.codice_abbonamento) {
              listaIdReturn.push(dati)
              sommaTotale += somma + Number(dati.costo)
              sommaiva = sommaTotale * 1.22
              iva = sommaiva - sommaTotale

            }
          })
          setListaDatiPacchetto(listaIdReturn)
          setSommaCostoIva(sommaiva)
          setSommaCosto(sommaTotale)
          setIva(iva)
          setListaIdpk(null)
          setCount(2)
          setShowAnnullaOrdine(true)
        }
      })
    }


  }

  const valoreDato = (dato) => {
    if(dato == null || dato === ""){
        return "Dato non fornito"
    }else{
        return dato
    }
}

const handleAggiungiPacchetto = (pacchetto) => {
    let messagioerrore = "Non puoi aggiungere questo pacchetto perchè è già presente nella lista di pacchetti scelti!"
    let elementoTrovato = listaDatiPacchetto.find(item => item === pacchetto);
    if (!elementoTrovato){
      if(listaDatiPacchetto.length > 0){
        listaDatiPacchetto.forEach(element => {
          if(pacchetto.codice_abbonamento === "AB1234" ||  pacchetto.codice_abbonamento === "AB1237" ||
          pacchetto.codice_abbonamento === "AB1238"){
            messagioerrore = "Non puoi aggiungere questo pacchetto perchè è già presente un pacchetto principale!"
            elementoTrovato = true
          }   
        });
      }
    }

    

    if (elementoTrovato) {
      setMessaggioPacchetto(messagioerrore)
      setShowControlloPacchetto(true)
    } else {
      setListaDatiPacchetto([...listaDatiPacchetto, pacchetto]);
      let somma = sommaCosto;
      let sommaTotale = somma + Number(pacchetto.costo)

      let iva = (sommaTotale * 1.22) - sommaTotale

      setIva(iva)
      setSommaCosto(sommaTotale)
      setSommaCostoIva(sommaTotale + iva)
      setShowCarrello(true)
    }
}

const handleServizioPagamento = (id) => {

  let listaId = []

  if(id === null) id = idOrdine

  listaDatiPacchetto.map((elemento, i) =>{
    listaId.push(elemento.codice_abbonamento)
  })

  creaPagamento(id, listaId).then((response) => {
    console.log(response);
    //window.open(response.data, "_blank");
    window.open(response.data, "_self");
})



}

const handleChiudiCarrello = () =>{
  setShowCarrello(false)
  setListaDatiPacchetto([])
  setSommaCosto(0)
  setSommaCostoIva(0)
  setIva(0)
  console.log("elimina carrello")
}


const handlePagaPaypal = (id) => {
  setIsLoading(true);
  let ordine = {
    data: moment(),
    metodoDiPagamento:"paypal",
    iva: iva,
    prezzoTotale: sommaCostoIva,
    pianoAbbonamento: "",
    statoOrdine: 0,
    id_login: jwtToken.data.id,
    id_utente: jwtToken.data.id,
    datiPacchetto: listaDatiPacchetto
  }

  getOrdine(ordine).then((response) => {
    if(response.status === 200){
      let data = {
        "datiPacchetto": listaDatiPacchetto,
        "costoTotale": sommaCostoIva,
        "iva": iva
      }
      setIdOrdine(response.data.id)
      setDatiRiepilogoPagamento(data)
      setShowOrdine(true);
      setIsLoading(false);
    }else{
      setIsLoading(false);
    }
  })
}

const handlePagaNexi = (id) => {
  setIsLoading(true);
  let ordine = {
    data: moment(),
    metodoDiPagamento:"nexi",
    iva: iva,
    prezzoTotale: sommaCostoIva,
    pianoAbbonamento: "",
    statoOrdine: 0,
    id_login: jwtToken.data.id,
    id_utente: jwtToken.data.id,
    datiPacchetto: listaDatiPacchetto
  }

  getOrdine(ordine).then((response) => {
    if(response.status === 200){
      let data = {
        "datiPacchetto": listaDatiPacchetto,
        "costoTotale": sommaCostoIva,
        "iva": iva
      }
      setIdOrdine(response.data.id)
      setDatiRiepilogoPagamento(data)
      setShowOrdine(true);
      setIsLoading(false);
    }else{
      setIsLoading(false);
    }
  })

  //navigate("/ws_login/", {replace: true})
}

const eliminaElemento = (pacchetto, index) => {
  // Crea una copia della lista senza l'elemento da eliminare
  const nuovaLista = listaDatiPacchetto.filter((_, i) => i !== index);
  // Aggiorna lo stato con la nuova lista
  setListaDatiPacchetto(nuovaLista);
  let somma = sommaCosto;
  let sommaTotale = somma - Number(pacchetto.costo)

  let iva = (sommaTotale * 1.22) - sommaTotale

  setIva(iva)
  setSommaCostoIva(sommaTotale + iva)
  setSommaCosto(sommaTotale)

  if(nuovaLista.length === 0){
    setShowCarrello(false)
    setListaDatiPacchetto([])
    setSommaCosto(0)
    setSommaCostoIva(0)
    setIva(0)
  }
};
  
  
  return (
    <Layout>
      {isLoading ? <LoadingSpinner /> : isLoading}
      <div className="container-fluid  paddin-left-0" >
      {((listaDatiPacchetto != null && listaDatiPacchetto.length > 0) || showCarrello) && 
      <div className="d-flex flex-column" >
          <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                <div className="col-sm-11">
                    <h5>Pacchetti Scelti</h5>
                </div>
                <div className="col-sm-1">
                  <i onClick={handleChiudiCarrello} className="fa-solid fa-trash color-gray"></i>
                </div>
          </Row>
          <div className="d-flex flex-column" >
          <Row className="margin-bottom-20">
            <div className="col-sm-9">
            <div className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
            {listaDatiPacchetto != null && listaDatiPacchetto.map((pacchetto, i) =>{
                return(
                <>
                  <div className="row w-100 carrello">
                    <div className="col-sm-1">
                      <i onClick={() => eliminaElemento(pacchetto, i)} className="fa-solid fa-trash color-gray"></i>
                    </div>
                    <div className="col-sm-9">
                        <h6 className="posizioni-lista">{pacchetto.nome_abbonamento}</h6>
                    </div>
                    <div className="col-sm-2 car-2">
                        <h6 className="boldtesto">€ {Number(pacchetto.costo).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })}</h6>
                    </div>
                  </div>
                  
              </>
           )
            
          })  
        }
        <div className="row w-100 cost">
            <div className="col-sm-8"></div>
            <div className="col-sm-2 posizioni-lista cost-iva">Iva</div>
            <div className="col-sm-2 posizioni-lista"><strong>€ {Number(iva).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })}</strong></div>
        </div>
        <div className="row w-100 cost">
            <div className="col-sm-8"></div>
            <div className="col-sm-2 posizioni-lista cost-iva">Totale iva inclusa</div>
            <div className="col-sm-2 posizioni-lista font-size-large"><strong>€ {Number(sommaCostoIva).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })}</strong></div>
        </div>
        </div>
        </div>
        <div className="col-sm-3">
          <div className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero margin-btn-pay">
            <>
            <div className="col-sm-12 center-block-custom">
            <a  className="w-100 display-block btn-paypal" 
                                            
                                            onClick={() => handlePagaPaypal(null)}>
                                                <i className="fa-brands fa-paypal"></i> Paga con PayPal</a>
            </div>
            <div className="col-sm-12 center-block-custom">
                                            <a  className="w-100 display-block btn-nexi" 
                                            
                                            onClick={() => handlePagaNexi(null)}>
                                                <i className="fa-solid fa-credit-card"></i> Paga con Carta</a>
             </div>
                                            
            </>
          </div>
        </div>
          </Row>
        </div>
        </div>
      }

      <div className="d-flex flex-column" >
          <Row className="shadow box-ricerca-elenco heigth-max ">
                <div className="col-sm-8">
                    <h4>Pacchetti Principali</h4>
                </div>
                <div className="col-sm-2">
                    <p className="crediti cursor-pointer" onClick={()=> setShowInfoAcquisto(true)}>* come acquistare?</p>
                </div>
                <div className="col-sm-2">
                    <p className="crediti cursor-pointer" onClick={()=> setShowInfoCrediti(true)}>* cosa sono i crediti?</p>
                </div>
                
          </Row>
        </div>
        <div className="d-flex flex-column" >
          <Row className="margin-bottom-20">
          {datiShop != null && datiShop.map((pacchetto, i) =>{
            if(pacchetto.codice_abbonamento === "AB1234" 
              || pacchetto.codice_abbonamento === "AB1237" 
              || pacchetto.codice_abbonamento === "AB1238"
              || pacchetto.codice_abbonamento === "AB1243"
              || pacchetto.codice_abbonamento === "AB1244"
              || pacchetto.codice_abbonamento === "AB1245"
            ){
              return(
              <>
                <div className="col-sm-4 margin-bottom-20">
                  <div className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
                  <div className="col-sm-12">
                                            <h5 className="posizioni-lista cursor-pointer">{pacchetto.nome_abbonamento}</h5>
                                            {pacchetto.codice_abbonamento === "AB1234" &&
                                            <span className="box-piani-span">Il più acquistato dai professionisti e studi</span>
                                            }
                                            
                                        </div>
                                        <div className="col-sm-6"></div>
                                        <div className="col-sm-12 min-height-250">
                                            <p><strong >Cosa contiene:</strong></p>
                                                <ul>
                                                    {pacchetto.ricercaunica > 0 && 
                                                    <li>Ricerca Imprese: <strong>{pacchetto.ricercaunica}</strong></li>
                                                    }
                                                    {pacchetto.dettaglio > 0 && 
                                                    <li>Dettaglio Imprese: <strong>{pacchetto.dettaglio}</strong></li>
                                                    }
                                                    {pacchetto.anagrafica > 0 && 
                                                    <li>Anagrafiche in File: <strong>{pacchetto.anagrafica}</strong></li>
                                                    }
                                                    {pacchetto.visure > 0 && 
                                                    <li>Visure Camerali: <strong>{pacchetto.visure}</strong></li>
                                                    }
                                                    {pacchetto.bilanci > 0 && 
                                                    <li>Bilanci Azienda: <strong>{pacchetto.bilanci}</strong></li>
                                                    }
                                                    {pacchetto.protesti > 0 && 
                                                    <li>Protesti: <strong>{pacchetto.protesti}</strong></li>
                                                    }
                                                    {pacchetto.catastali > 0 && 
                                                    <li>Visure Catastali: <strong>{pacchetto.catastali}</strong></li>
                                                    }
                                                    {pacchetto.reportsmart > 0 && 
                                                    <li>Report Smart: <strong>{pacchetto.reportsmart}</strong></li>
                                                    }
                                                    {pacchetto.deepdossier > 0 && 
                                                    <li>Report Azienda Base: <strong>{pacchetto.deepdossier}</strong></li>
                                                    }
                                                    {pacchetto.executivereport > 0 && 
                                                    <li>Report Azienda Approfondito: <strong>{pacchetto.executivereport}</strong></li>
                                                    }
                                                    {pacchetto.reportpersona > 0 && 
                                                    <li>Report Persona Base: <strong>{pacchetto.reportpersona}</strong></li>
                                                    }
                                                    {pacchetto.reportpersonacompleto > 0 && 
                                                    <li>Report Persona Approfondito: <strong>{pacchetto.executivereport}</strong></li>
                                                    }
                                                </ul>
                                            <p></p>
                                        </div>
                                        <div className="col-md-6"><h5 className="posizioni-lista">€ {Number(pacchetto.costo).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })} <span className="font-size-small"> + Iva</span></h5></div>
                                        <div className="col-md-6 align-right">
                                            <Button variant="success" className="" 
                                            disabled={false} 
                                            onClick={() => handleAggiungiPacchetto(pacchetto)}>
                                                <i className="fa-solid fa-shopping-cart"></i> Acquista</Button>
                                        </div>
                  </div>
                </div>
              </>
           )
          }
            
          })  
        }
          </Row>
          {showInfoCrediti && 
                  <ConfirmationInfoCrediti buttonNegative="Chiudi" onNegative={() => setShowInfoCrediti(false)} title={"Crediti MyImprese"} />
                }
          {showInfoAcquisto &&
            <ConfirmationInfoAcquisto buttonNegative="Chiudi" onNegative={() => setShowInfoAcquisto(false)} title={"Come Acquistare"} />
          }
        </div>
        <div className="d-flex flex-column" >
          <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                <div className="col-sm-10">
                    <h5>Pacchetti Aggiuntivi</h5>
                </div>
          </Row>
        </div>
        <div className="d-flex flex-column" >
        <Row className="margin-bottom-20">
          {datiShopAggiuntive != null && datiShopAggiuntive.map((pacchetto, i) =>{
            if(pacchetto.codice_abbonamento != "AB1234" 
              && pacchetto.codice_abbonamento != "AB1237" 
              && pacchetto.codice_abbonamento != "AB1238"
              && pacchetto.codice_abbonamento != "AB1243"
              && pacchetto.codice_abbonamento != "AB1244"
              && pacchetto.codice_abbonamento != "AB1245"
              && pacchetto.codice_abbonamento != "AB1246"
              && pacchetto.codice_abbonamento != "AB1247"
              && pacchetto.codice_abbonamento != "AB1248"
              && pacchetto.codice_abbonamento != "AB1249"
              && pacchetto.codice_abbonamento != "AB1250"
            ){
            return(
              <>
              <div className="col-sm-4 margin-bottom-20">
              <div className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
                  <div className="col-sm-12">
                                            <h5 className="pacchetto-aggiuntivo cursor-pointer">{pacchetto.nome_abbonamento}</h5>
                                        </div>
                                        <div className="col-sm-6"></div>
                                        <div className="col-sm-12 ">
                                            <p><strong>Cosa contiene:</strong></p>
                                                <ul>
                                                {pacchetto.ricercaunica > 0 && 
                                                    <li>Ricerca Imprese: <strong>{pacchetto.ricercaunica}</strong></li>
                                                    }
                                                    {pacchetto.dettaglio > 0 && 
                                                    <li>Dettaglio Imprese: <strong>{pacchetto.dettaglio}</strong></li>
                                                    }
                                                    {pacchetto.anagrafica > 0 && 
                                                    <li>Anagrafiche in File: <strong>{pacchetto.anagrafica}</strong></li>
                                                    }
                                                    {pacchetto.visure > 0 && 
                                                    <li>Visure Camerali: <strong>{pacchetto.visure}</strong></li>
                                                    }
                                                    {pacchetto.bilanci > 0 && 
                                                    <li>Bilanci Azienda: <strong>{pacchetto.bilanci}</strong></li>
                                                    }
                                                    {pacchetto.protesti > 0 && 
                                                    <li>Protesti: <strong>{pacchetto.protesti}</strong></li>
                                                    }
                                                    {pacchetto.catastali > 0 && 
                                                    <li>Visure Catastali: <strong>{pacchetto.catastali}</strong></li>
                                                    }
                                                    {pacchetto.reportsmart > 0 && 
                                                    <li>Report Smart: <strong>{pacchetto.reportsmart}</strong></li>
                                                    }
                                                    {pacchetto.deepdossier > 0 && 
                                                    <li>Report Azienda Base: <strong>{pacchetto.deepdossier}</strong></li>
                                                    }
                                                    {pacchetto.executivereport > 0 && 
                                                    <li>Report Azienda Approfondito: <strong>{pacchetto.executivereport}</strong></li>
                                                    }
                                                    {pacchetto.reportpersona > 0 && 
                                                    <li>Report Persona Base: <strong>{pacchetto.reportpersona}</strong></li>
                                                    }
                                                    {pacchetto.reportpersonacompleto > 0 && 
                                                    <li>Report Persona Approfondito: <strong>{pacchetto.executivereport}</strong></li>
                                                    }
                                                </ul>
                                            <p></p>
                                        </div>
                                        <div className="col-md-6"><h5 className="pacchetto-aggiuntivo">€ {Number(pacchetto.costo).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })} <span className="font-size-small"> + Iva</span></h5></div>
                                        <div className="col-md-6 align-right">
                                            <Button variant="info" className="" 
                                            disabled={false} 
                                            onClick={() => handleAggiungiPacchetto(pacchetto)}>
                                                <i className="fa-solid fa-shopping-cart"></i> Acquista</Button>
                                            {"     "}
                                            {/* <Button variant="danger" className="" disabled={listaSalvate === null || (listaSalvate != null && listaSalvate.length === 0)} onClick={() => handleEliminaRicerca(lista.id_lista)}><i className="fa-solid fa-trash"></i> Elimina</Button> */}
                                        </div>
                  </div>
              </div>
            </>
            )
            }
          })
           
        }
           </Row>
        </div>
        <div className="d-flex flex-column" >
          <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                <div className="col-sm-10">
                    <h5>Servizi Singoli</h5>
                </div>
          </Row>
        </div>
        <div className="d-flex flex-column" >
        <Row className="margin-bottom-20">
          {datiShopAggiuntive != null && datiShopAggiuntive.map((pacchetto, i) =>{
            if(pacchetto.codice_abbonamento != "AB1234" 
              && pacchetto.codice_abbonamento != "AB1237" 
              && pacchetto.codice_abbonamento != "AB1238"
              && pacchetto.codice_abbonamento != "AB1243"
              && pacchetto.codice_abbonamento != "AB1244"
              && pacchetto.codice_abbonamento != "AB1245"
              && pacchetto.codice_abbonamento != "AB1235"
              && pacchetto.codice_abbonamento != "AB1236"
              && pacchetto.codice_abbonamento != "AB1239"
              && pacchetto.codice_abbonamento != "AB1240"
              && pacchetto.codice_abbonamento != "AB1241"
              && pacchetto.codice_abbonamento != "AB1242"
            ){
            return(
              <>
              <div className="col-sm-4 margin-bottom-20">
              <div className="shadow box-ricerca-elenco row heigth-max shadow-margin-zero">
                  <div className="col-sm-12">
                                            <h5 className="pacchetto-aggiuntivo cursor-pointer">{pacchetto.nome_abbonamento}</h5>
                                        </div>
                                        <div className="col-sm-6"></div>
                                        <div className="col-sm-12 ">
                                            <p><strong>Cosa contiene:</strong></p>
                                                <ul>
                                                {pacchetto.ricercaunica > 0 && 
                                                    <li>Ricerca Imprese: <strong>{pacchetto.ricercaunica}</strong></li>
                                                    }
                                                    {pacchetto.dettaglio > 0 && 
                                                    <li>Dettaglio Imprese: <strong>{pacchetto.dettaglio}</strong></li>
                                                    }
                                                    {pacchetto.anagrafica > 0 && 
                                                    <li>Anagrafiche in File: <strong>{pacchetto.anagrafica}</strong></li>
                                                    }
                                                    {pacchetto.visure > 0 && 
                                                    <li>Visure Camerali: <strong>{pacchetto.visure}</strong></li>
                                                    }
                                                    {pacchetto.bilanci > 0 && 
                                                    <li>Bilanci Azienda: <strong>{pacchetto.bilanci}</strong></li>
                                                    }
                                                    {pacchetto.protesti > 0 && 
                                                    <li>Protesti: <strong>{pacchetto.protesti}</strong></li>
                                                    }
                                                    {pacchetto.catastali > 0 && 
                                                    <li>Visure Catastali: <strong>{pacchetto.catastali}</strong></li>
                                                    }
                                                    {pacchetto.reportsmart > 0 && 
                                                    <li>Report Smart: <strong>{pacchetto.reportsmart}</strong></li>
                                                    }
                                                    {pacchetto.deepdossier > 0 && 
                                                    <li>Report Azienda Base: <strong>{pacchetto.deepdossier}</strong></li>
                                                    }
                                                    {pacchetto.executivereport > 0 && 
                                                    <li>Report Azienda Approfondito: <strong>{pacchetto.executivereport}</strong></li>
                                                    }
                                                    {pacchetto.reportpersona > 0 && 
                                                    <li>Report Persona Base: <strong>{pacchetto.reportpersona}</strong></li>
                                                    }
                                                    {pacchetto.reportpersonacompleto > 0 && 
                                                    <li>Report Persona Approfondito: <strong>{pacchetto.executivereport}</strong></li>
                                                    }
                                                </ul>
                                            <p></p>
                                        </div>
                                        <div className="col-md-6"><h5 className="pacchetto-aggiuntivo">€ {Number(pacchetto.costo).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })} <span className="font-size-small"> + Iva</span></h5></div>
                                        <div className="col-md-6 align-right">
                                            <Button variant="info" className="" 
                                            disabled={false} 
                                            onClick={() => handleAggiungiPacchetto(pacchetto)}>
                                                <i className="fa-solid fa-shopping-cart"></i> Acquista</Button>
                                            {"     "}
                                            {/* <Button variant="danger" className="" disabled={listaSalvate === null || (listaSalvate != null && listaSalvate.length === 0)} onClick={() => handleEliminaRicerca(lista.id_lista)}><i className="fa-solid fa-trash"></i> Elimina</Button> */}
                                        </div>
                  </div>
              </div>
            </>
            )
            }
          })
           
        }
           </Row>
        </div>
      </div>
      <ConfirmationOrdine
          buttonNegative="Chiudi" 
          buttonPositive="Conferma" 
          title="Acquisto Pacchetto" 
          show={showOrdine} 
          body={datiRiepilogoPagamento} 
          onNegative={() => setShowOrdine(false)}
          onPositive={() => handleServizioPagamento(idOrdine)}  
      />

      <ConfirmationAnnullaOrdine
          buttonNegative="Chiudi" 
          //buttonPositive="Conferma" 
          title="Annulla Acquisto Pacchetto" 
          show={showAnnullaOrdine} 
          body="Il tuo ordine è stato annullato, per proseguire effettua di nuovo il pagamento" 
          onNegative={() => setShowAnnullaOrdine(false)}
          //onPositive={() => handleServizioPagamento(idOrdine)}  
      />
      <ConfirmationAggiuntaPacchettoErrore
          buttonNegative="Chiudi" 
          title="Controllo presenza pacchetti" 
          show={showControlloPacchetto} 
          body={messaggioPacchetto}
          onNegative={() => setShowControlloPacchetto(false)} 
      />
      <ConfirmationEsitoOrdine
        buttonNegative="Chiudi" 
        title="Esito Ordine" 
        show={showEsitoOrdine} 
        body={datiEsitoOrdine}
        onNegative={() => setShowEsitoOrdine(false)} 
      />
      </Layout >
  );
};

