import { render } from '@testing-library/react';
import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { List } from 'reselect/es/types';
import { getCodici_Ateco } from 'services/codici_atecoService';
import { brotliDecompress } from 'zlib';

type Props = {
    show: boolean;
    title: ReactNode;
    body: ReactNode;
    buttonPositive: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive: () => void;
    onNegative: () => void;
};

const stampaDettaglio = (elementi) =>{
    return (
        elementi.forEach(element => {
            return(
                <div className="col-sm-12"><p>{element}</p></div>
            )
        })
    )
}

const visualizzaUnitaLocali =  (datiRicerca) =>{
    // unitaLocali.forEach((el) =>{
    //     return (<div className="col-sm-12">Denominazione: {el.StatoAttivitaR }</div>)
    // })
    console.log(datiRicerca.dati.dati_ricerca)

    var split = datiRicerca.dati.dettaglio.split(" __")

    var atecolista = datiRicerca.ateco
    var comuneLista = datiRicerca.comuni
    console.log(datiRicerca.comuni)

    //console.log(split)

    var statoImpresa = "Non Indicata";
    var addetti = "Non Indicato";
    var capitaleSoc = "Non Indicato";
    var classeForma = "Non Indicata";
    var provincia = "Non Indicata";
    var comune = "Non Indicato";
    var dtIscrizioneMin = "Non Indicato"
    var dtIscrizioneMax = "Non Indicato"
    var dtCancellazioneMin = "Non Indicato"
    var dtCancellazioneMax = "Non Indicato"
    var codiceAttivita = "Non Indicato"

    

    return(
        <div>
            <div className='col-sm-12'>
                <p className='icon-confirm-small color-blue'><i className="fa fa-check-circle"></i></p>
            </div>
            <div className='col-sm-12'>
                <p><strong>Dati ricerca</strong></p>
            </div>
            <div className='col-sm-12'>
                <div className='row'>
                {
                    split.map((element, index) => {
                        return(
                            <div key={index} className="col-sm-6"><p>{element}</p></div>
                        )
                    })
                }
                </div>
            </div>
            
            
            <div className="col-sm-12"><p></p></div>
            <div className="col-sm-12"><strong>Informazioni: </strong>Attraverso il pulsante <strong>Richiedi Elenco</strong> sarà possibile effettuare nuovamente la ricerca e ricevere il preventivo per la successiva consultazione</div>
        </div>
    )

    
    
    // return (datiRicerca.map((item, i) => {
    //     let progressivo = i + 1;
    //     return (
    //     <div className="row">
    //         <div className="col-sm-12">Progressivo: {progressivo}</div>
    //         <div className="col-sm-6">Stato Attività: {item.StatoAttivitaR }</div>
    //         <div className="col-sm-6">Ateco: {item.Ateco07 }</div>
    //         <div className="col-sm-6">Indirizzo: {item.Indirizzo.Toponimo}  {item.Indirizzo.Via}  {item.Indirizzo.NCivico} -  {item.Indirizzo.Cap}  {item.Indirizzo.Comune} ( {item.Indirizzo.Provincia})</div>

    //     </div>
        
        
    //     )
    // })
    
    // )
} 

const elaboraDati =  (provincia, comune, statoImpresa,classeForma, codiceAttivita, dtIscrizioneMin) =>{

    return(
        <div>
            <div className="col-sm-6">Provincia: {provincia}</div>
            <div className="col-sm-6">Comune: {comune}</div>
            <div className="col-sm-6">Stato Impresa: {statoImpresa}</div>
            <div className="col-sm-6">Tipologia Impresa: {classeForma}</div>
            <div className="col-sm-6">Codice Ateco: {codiceAttivita}</div>
            
            <div className="col-sm-6">Data Iscrizione Da: {dtIscrizioneMin}</div>
        </div>
    )

}


const DetailRicercaEstesaModal: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    console.log("-------------------")
    console.log(body)
    if(body != null){
        return (
            <Modal size="xl" show={show}  dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className="row">
                                <div className="col-sm-12">{visualizzaUnitaLocali(body)}</div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                    <Button onClick={() => onPositive()} variant="success" disabled={disableButton}>
                        {buttonPositive}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }else{
        return(
            <Modal size="lg" show={show}  dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Richiesta scaduta, riprova la ricerca e richiedere dettaglio</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

    }
};

export default DetailRicercaEstesaModal;

