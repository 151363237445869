import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../asset/logo_myimprese.png"
import { getOneWs_Login } from 'services/ws_loginService';
import { parseJwtToken } from 'services/tokenService';

const NavigationMenu = ({ isLoading }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLoging = () => {
    if(datiLoad != null)navigate('/ricerca_unica', { replace: true });
    if(datiLoad == null)navigate('/', { replace: true });
  };

  const handleAssistenza = () => {
    navigate('/assistenza', { replace: true });
  };

  const MenuItems = () => (
    <>
      <p
        onClick={handleLoging}
        className="font-menu cursor-pointer hover:text-blue-500 transition-colors"
      >
        Home
      </p>
      <p onClick={handleAssistenza} className="font-menu cursor-pointer hover:text-blue-500 transition-colors">
                  Assistenza
      </p>

      {datiLoad != null &&
                  <p className="font-menu cursor-pointer hover:text-blue-500 transition-colors" onClick={handleLogout}>Logout</p>
                }

    </>
  );

  const handleServizi= () => {
    navigate('/#servizi', { replace: true });
    // Dopo la navigazione, scorri manualmente all'elemento con ID "servizi"
  const element = document.getElementById('servizi');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
  }

  const token = localStorage.getItem('token');
  const jwtToken = parseJwtToken(token);

  var idUtente = 0
    let iValueUser = {
        id: '',
        id_utente: '',
    }
    const iValueEmpty = {
        id: '',
        id_utente: '',
    }
    const[datiLoad, setDatiLoad] = useState(null);

  useEffect(() => {
        
    if(jwtToken && jwtToken.data != null ){
        idUtente = jwtToken.data.id;

        if(datiLoad == null){
            getOneWs_Login(idUtente).then((response) => {
                if (response && response.data && response.data.document) {
                  console.log(response);
                  const iValueUser_set = {
                    id: '',
                    id_utente: response.data.document.id,
                }
                iValueUser = iValueUser_set;
                setDatiLoad(iValueUser);

                }else{
                    iValueUser = iValueEmpty
                    setDatiLoad(iValueUser);
                }
              })
        }
        
    }else{
        iValueUser = iValueEmpty
    }
  }, [datiLoad]);

  const handleLogout = () => {
    localStorage.setItem('token', 'vuoto')
    localStorage.clear();
    navigate('/', { replace: true});
    navigate(0);
}

const handleHome= () => {
  navigate('/', { replace: true });
}

  return (
    <>
        <div>
          {/* Desktop Navigation */}
          <div className="nav-desktop row menu-basic align-items-center px-custom">
            <div className="col-sm-4">
              <img src={logo} alt="Logo" className="logo-public" onClick={handleHome}/>
            </div>
            <div className="col-sm-8">
              <div className="d-flex justify-content-end gap-3">
                <p onClick={handleLoging} className="font-menu">
                  Home
                </p>
                <p onClick={handleAssistenza} className="font-menu">
                  Assistenza
                </p>
                {datiLoad != null &&
                  <p className="font-menu" onClick={handleLogout}>Logout</p>
                }
              </div>
            </div>
          </div>

          {/* Mobile Navigation */}
          <div className="nav-mobile row menu-basic align-items-center px-custom relative paddind-lr-m-15 min-height-auto">
            <div className="row full-width">
              <div className="col-sm-6 dim-custom-80">
                <img src={logo} alt="Logo" className="logo-public" onClick={handleHome}/>
              </div>
              <div className="col-sm-6 dim-custom-20 flex text-center">
                <i
                  className="fa fa-bars icon-bar-sm"
                  onClick={toggleMobileMenu}
                ></i>
              </div>
            </div>

            {/* Mobile Menu Overlay */}
            {isMobileMenuOpen && (
              <div className="fixed inset-0 bg-white z-40 pt-20">
                <div className="flex flex-col items-center space-y-6">
                  <MenuItems />
                </div>
              </div>
            )}
          </div>
        </div>
    </>
  );
};

export default NavigationMenu;
