import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';

type Props = {
    show?: boolean;
    title: ReactNode;
    body?: ReactNode;
    buttonPositive?: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive?: () => void;
    onNegative: () => void;
};

const ConfirmationInfoSoci: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    return (
        <Modal size="lg" show={true} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='row'>
                                <div className='col-sm-12'>
                                    <p className='icon-alert color-blue'><i className="fa fa-info-circle"></i></p>
                                </div>
                                <div className='col-sm-12'>
                                    <p>
                                    I soci sono le persone fisiche o giuridiche che detengono una parte della proprietà di 
                                    un'organizzazione o di un'azienda. Essi possono essere coinvolti direttamente nella gestione 
                                    (ad esempio, partecipando alle assemblee) oppure semplicemente come investitori. 
                                    La loro principale caratteristica è il possesso di una quota del capitale sociale.
                                    </p>

                                </div>
                            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                    {buttonNegative}
                </Button>
                {/* <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                    {buttonPositive}
                </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationInfoSoci;

