import React, { useEffect, useState } from 'react';
import menu_icon from "../../asset/icon_menu.png"
import { useNavigate } from "react-router";
import { parseJwtToken } from 'services/tokenService';
import { getOneWs_Login } from 'services/ws_loginService';


const Navbar = ({ logo }) => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleAssistenza = () => {
    navigate('/assistenza', { replace: true });
  }

  const handleHome= () => {
    navigate('/', { replace: true });
  }

  const handleServizi= () => {
    navigate('#servizi', { replace: true });
    // Dopo la navigazione, scorri manualmente all'elemento con ID "servizi"
  const element = document.getElementById('servizi');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
  }

  const handlePrezzi= () => {
    navigate('#prezzi', { replace: true });
    // Dopo la navigazione, scorri manualmente all'elemento con ID "servizi"
  const element = document.getElementById('prezzi');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
  }

  const handleChiSiamo= () => {
    navigate('#chisiamo', { replace: true });
    // Dopo la navigazione, scorri manualmente all'elemento con ID "servizi"
  const element = document.getElementById('chisiamo');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
  }

  const handleLoging = () => {
    if(datiLoad != null)navigate('/ricerca_unica', { replace: true });
    if(datiLoad == null)navigate('/', { replace: true });
  };

  const MenuItems = () => (
    <>
    {datiLoad != null &&
                          <p className="font-menu" onClick={handleLoging}>Home</p>
                      }
      <p className="font-menu cursor-pointer hover:text-blue-500 transition-colors" onClick={handleServizi}>Servizi</p>
      <p className="font-menu cursor-pointer hover:text-blue-500 transition-colors" onClick={handlePrezzi}>Prezzi</p>
      <p className="font-menu cursor-pointer hover:text-blue-500 transition-colors" onClick={handleChiSiamo}>Chi Siamo</p>
      <p className="font-menu cursor-pointer hover:text-blue-500 transition-colors" onClick={handleAssistenza}>Assistenza</p>
      {datiLoad != null &&
        <p className="font-menu cursor-pointer hover:text-blue-500 transition-colors" onClick={handleLogout}>Logout</p>
      }
    </>
  );
  const token = localStorage.getItem('token');
  const jwtToken = parseJwtToken(token);

  var idUtente = 0
    let iValueUser = {
        id: '',
        id_utente: '',
    }
    const iValueEmpty = {
        id: '',
        id_utente: '',
    }
    const[datiLoad, setDatiLoad] = useState(null);

  useEffect(() => {

    if(jwtToken && jwtToken.data != null ){
        idUtente = jwtToken.data.id;

        if(datiLoad == null){
            getOneWs_Login(idUtente).then((response) => {
                if (response && response.data && response.data.document) {
                  console.log(response);
                  const iValueUser_set = {
                    id: '',
                    id_utente: response.data.document.id,
                }
                iValueUser = iValueUser_set;
                setDatiLoad(iValueUser);

                }else{
                    iValueUser = iValueEmpty
                    setDatiLoad(iValueUser);
                }
              })
        }
        
    }else{
        iValueUser = iValueEmpty
    }
  }, [datiLoad]);

  const handleLogout = () => {
    localStorage.setItem('token', 'vuoto')
    localStorage.clear();
    navigate('/', { replace: true});
    navigate(0);
}

  return (
    <>
      {/* Desktop Navigation */}
      <div className="nav-desktop row menu-basic align-items-center px-custom">
      
                <div className="col-sm-4">
                    <img src={logo} alt="" className="logo-public" onClick={handleHome}/>
                </div>
                <div className="col-sm-8">
                    <div className="d-flex justify-content-end gap-3">
                      {datiLoad != null &&
                          <p className="font-menu" onClick={handleLoging}>Home</p>
                      }
                        <p className="font-menu" onClick={handleServizi}>Servizi</p>
                        <p className="font-menu" onClick={handlePrezzi}>Prezzi</p>
                        <p className="font-menu" onClick={handleChiSiamo}>Chi Siamo</p>
                        <p className="font-menu" onClick={handleAssistenza}>Assistenza</p>
                        {datiLoad != null &&
                          <p className="font-menu" onClick={handleLogout}>Logout</p>
                        }
                        
                    </div>
                </div>
             
      </div>

      {/* Mobile Navigation */}
      <div className="nav-mobile row menu-basic align-items-center px-custom relative paddind-lr-m-15 min-height-auto">
       <div className='row full-width'>
       <div className="col-sm-6 dim-custom-80">
          <img src={logo} alt="Logo" className="logo-public" onClick={handleHome}/>
        </div>
        <div className="col-sm-6 dim-custom-20 flex text-center">
          {/* <button onClick={toggleMobileMenu} className="z-50 relative">
            {isMobileMenuOpen ? <img src={menu_icon} /> : <img src={menu_icon} />}
          </button> */}
          <i className="fa fa-bars icon-bar-sm" onClick={toggleMobileMenu}></i>
        </div>
       </div>
        

        {/* Mobile Menu Overlay */}
        {isMobileMenuOpen && (
          <div className="fixed inset-0 bg-white z-40 pt-20">
            <div className="flex flex-col items-center space-y-6">
              <MenuItems />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
