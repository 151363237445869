import LineChart from "components/LineChart";
import { UserData } from "Data";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setAreaGeograficaList, setAreaGeograficaMessage, setCodici_AtecoList, setCodici_AtecoMessage, setComuni_CatastaliList, setComuni_CatastaliMessage, setTrovaImpresaList, setTrovaImpresaMessage, setWs_ProvincieinfocamereList, setWs_ProvincieinfocamereMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getRicercaImpresaCodiceFiscale, getRicercaImpresaDenominazione, getRicercaImpresaPartitaIva, getTrovaImpresa } from "services/ricerca_impresa_service";
import Layout from "template";
import { Dettaglio} from "./Dettaglio";
import LoadingSpinner from "../../LoadingSpinner";
import { getVisuraCamerale } from "services/richiesta_visura";
import { getComuni_Catastali } from "services/comuni_catastaliService";
import { getCodici_Ateco } from "services/codici_atecoService";
import ModalErrorGenerico from "template/ModalErrorGenerico";
import ConfirmationDatiObbligatoriElenco from "template/ConfirmationDatiObbligatoriElenco";


export const DettaglioAzienda: React.FC = () => {
  const dispatch = useAppDispatch();
  const token = localStorage.getItem('token');
  let nome_azienda = "";
  let partita_iva = "";
  const [action, setAction] = useState('');
  const rData = useSelector((state: RootState) => state.trova_impresa);
  const rDataProvincia = useSelector((state: RootState) => state.ws_provincieinfocamere);
  const rDataComune = useSelector((state: RootState) => state.comuni_catastali);
  const rDataAteco = useSelector((state: RootState) => state.codici_ateco);
  const rDataAreaGeografica = useSelector((state: RootState) => state.area_geografica);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorGenerico, setErroreGenerico] = useState(false);
  const [showRicercaVuota, setRicercaVuota] = useState(false);
  const [showMessaggioGenerico, setMessaggioGenerico] = useState(false);
  const handleServerDelete = async () => {}


  let numero_imprese = 0;
  const getData = (page, pageSize, searchKey, datiricerca) => {
    setIsLoading(true);
    if(datiricerca.tiporicerca === 1){

    }
  }
  
  useEffect(() => {
    //getDataAreaGeografica();
    //getDataCodiceAteco(1, 10000);
    
  }, []);
  
  
  return (
    <Layout>
      {isLoading ? <LoadingSpinner /> : isLoading}
      <div className="container-fluid  paddin-left-0" >
        {/* <div className="d-sm-flex align-items-center justify-content-between mb-4" >
          <h1 className="h3 mb-0 text-gray-800" > Dettaglio </h1>
        </div> */}
        <div className="d-flex flex-column min-vh-100 p-left-15-sm" >
          <Row>
            <Dettaglio getData={getData} numero_imprese={numero_imprese} /> 
          </Row>

          <ModalErrorGenerico buttonNegative="Chiudi" 
                                buttonPositive="Chiudi" 
                                title="Errore Generico Nella Richiesta" 
                                show={showErrorGenerico} 
                                body="Abbiamo rilevato un errore generico nella richiesta. Riprovare, se il problema persiste contattare il servio vliente. Grazie"
                                onNegative={() => setErroreGenerico(false)} 
                                onPositive={handleServerDelete} />
          <ModalErrorGenerico buttonNegative="Chiudi" 
                                buttonPositive="Chiudi" 
                                title="Ricerca Vuota" 
                                show={showRicercaVuota} 
                                body="La ricerca non ha prodotto risultati. Riprovare con altri parametri. Grazie"
                                onNegative={() => setRicercaVuota(false)} 
                                onPositive={handleServerDelete} />
          <ConfirmationDatiObbligatoriElenco 
                            buttonNegative="Continua" 
                            buttonPositive="Continua" 
                            title="Ricerca Impresa Puntuale" 
                            show={showErrorGenerico} 
                            body={showMessaggioGenerico} 
                            onNegative={() => setErroreGenerico(false)} 
                            />
          {/* <Row>
            {MenuItems.map((item, i) => {
              return <DashboardCard key={`Card-${i}`} name={item.title} path={item.path} />
            })}
          </Row> */}
        </div>
      </div>
      </Layout >
  );
};

