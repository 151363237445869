export const MenuItems = [
    // { title: 'Home', path: '/dashboard', icon: 'fas fa-fw fa-tachometer-alt', subMenu: [] },
    // { title: 'File Upload', path: '/upload', icon: 'fas fa-fw fa-upload', subMenu: [] },
    // { title: 'Ws_Prodotti', path: '/ws_prodotti', icon: 'fas fa-fw fa-table',subMenu: []},
    // { title: 'Ws_Log_Richieste', path: '/ws_log_richieste', icon: 'fas fa-fw fa-table',subMenu: []},
    // { title: 'Ws_Login', path: '/ws_login', icon: 'fas fa-fw fa-table',subMenu: []},
    // { title: 'Ws_Addebiti', path: '/ws_addebiti', icon: 'fas fa-fw fa-table',subMenu: []},
    // { title: 'Elenco Imprese Storico', path: '/elenco_imprese_esteso', icon: 'fas fa-fw fa-table',subMenu: []},
    // { title: 'Ricerca Imprese', path: '/ricerca_imprese', icon: 'fas fa-fw fa-tachometer-alt', subMenu: [] },
    { title: 'Ricerca Imprese', path: '/ricerca_unica', icon: 'fas fa-fw fa-search-location', subMenu: [] },
    { title: 'Le mie Ricerche', path: '/lista_ricerche', icon: 'fas fa-fw fa-list-ol', subMenu: [] },
    { title: 'Shop', path: '/shop', icon: 'fas fa-fw fa-store', subMenu: [] },
    { title: 'I miei Ordini', path: '/ordini', icon: 'fas fa-fw fa-shopping-cart', subMenu: [] },
    // { title: 'Elenco Imprese', path: '/elenco_imprese', icon: 'fas fa-fw fa-tachometer-alt', subMenu: [] },
    // { title: 'Elenco Imprese Table', path: '/elenco_imprese_esteso_estrazione', icon: 'fas fa-fw fa-table',subMenu: []},
    { title: 'Documenti in Attesa', path: '/ws_ticket', icon: 'fa fa-hourglass-end',subMenu: []},
    { title: 'Archivio Documenti', path: '/archivio', icon: 'fa fa-archive',subMenu: []},
    { title: 'I miei Crediti', path: '/piano_abbonamento', icon: 'fa fa-credit-card',subMenu: []},
    // { title: 'Riepilogo Estrazioni', path: '/piano_estrazioni', icon: 'fa fa-list-ol',subMenu: []},
    { title: 'Assistenza', path: '/richieste_assistenza', icon: 'fa fa-envelope-open',subMenu: []},
    // { title: 'Tipologia Abbonamento', path: '/tipologie_abbonamento', icon: 'fas fa-fw fa-table',subMenu: []},
    // { title: 'Grafici', path: '/elenco_imprese_grafici', icon: 'fas fa-fw fa-table',subMenu: []},
    
    // { title: 'anagrafica', path: '/ws_anagrafica_prodotto', icon: 'fas fa-fw fa-table',subMenu: []},
]