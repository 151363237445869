import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetLoginToInit, setLoginMessage } from "redux/actions";
import { Alert, Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import ReactPaginate from "react-paginate";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import * as yup from 'yup';
import { cambioPasswordAPICALL } from "services/authService";
import { parseJwtToken } from "services/tokenService";

type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const CambioPasswordTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rData = useSelector((state: RootState) => state.authToken);
    const[messaggioErrore, setMessaggioErrore] = useState(null)
    const[messaggioCambio, setMessaggioCambio] = useState(null)
    const searchParam = window.location.search;
    const params = new URLSearchParams(searchParam)
  
    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    let idUtente = -1
    if(jwtToken)
    {
        idUtente = jwtToken.data.id;
    }else{
        idUtente = 0; 
    }
    
    const formik = useFormik({
        initialValues: {
            confirmpassword: '',
            password: '',
            prepassword: ''
        },
        onSubmit: async (values) => {
            setMessaggioErrore(null)
            setMessaggioCambio(null)
            if(values.password != values.confirmpassword){
                setMessaggioErrore("Password e Conferma Password non coincidono!")
            }else if(values.password.length < 8){
                setMessaggioErrore("Password deve essere almeno di 8 caratteri!")
            }else{
                
                const response = await cambioPasswordAPICALL(values.password, values.prepassword, idUtente);
                if (response.status === 200 && response.document) {
                    setMessaggioCambio(response.response)
                } else {
                    setMessaggioErrore(response.response)
                }
            }
        },
        validationSchema: yup.object({
            confirmpassword: yup.string().trim().required('Conferma Password è obbligatorio e deve essere almeno di 8 caratteri'),
            password: yup.string().trim().required('Password è obbligatorio'),
            prepassword: yup.string().trim().required('Password Precedente è obbligatorio'),
        }),
    });

    useEffect(() => {
        document.body.classList.toggle('bg-gradient-primary', true);
    }, [])
    
    useEffect(() => {
        if (rData) {
            dispatch(resetLoginToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }else{
            
        }
    },[rData])



    const handleAddButtonClick = () => {
        dispatch(setLoginMessage(''));
        hideShowForm('add');
    }


    return (
        <div className="mb-4">
            <div className="d-flex flex-column" >
          <Row className="shadow box-ricerca-elenco heigth-max ">
                <div className="col-sm-10">
                    <h4>Pacchetti Principali</h4>
                </div>
          </Row>
        </div>
            <div>
            <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9 shadow box-ricerca-elenco">
                    <div className="o-hidden border-0 my-5">
                        <div className=" p-0">
                            <div className="row">
                                {/* <div className="col-lg-6 d-none d-lg-block bg-login-image"></div> */}
                                <div className="col-lg-12">
                                    <div className="">
                                        {messaggioErrore &&
                                            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{messaggioErrore}
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setMessaggioErrore('')}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert>
                                        }
                                        {messaggioCambio &&
                                            <Alert variant={Constant.defaultAlertSuccess} className="alert-dismissible fade">{messaggioCambio}
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setMessaggioCambio('')}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert>
                                        }
                                        {/* <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Cambio Password</h1>
                                        </div> */}
                                        <Form className="user" onSubmit={formik.handleSubmit}>
                                            <Form.Group>
                                                <label className="form-control-label">Password Precedente</label>
                                                <Form.Control type="password" name="prepassword" className="form-control" value={formik.values.prepassword}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!formik.touched.password && !!formik.errors.prepassword}
                                                    isValid={!!formik.touched.password && !formik.errors.prepassword}
                                                ></Form.Control>
                                                {formik.errors.prepassword && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.prepassword}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <Form.Group>
                                                <label className="form-control-label">Password</label>
                                                <Form.Control type="password" name="password" className="form-control" value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!formik.touched.password && !!formik.errors.password}
                                                    isValid={!!formik.touched.password && !formik.errors.password}
                                                ></Form.Control>
                                                {formik.errors.password && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.password}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <Form.Group>
                                                <label className="form-control-label">Conferma Password</label>
                                                <Form.Control type="password" name="confirmpassword" className="form-control" value={formik.values.confirmpassword}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!formik.touched.password && !!formik.errors.confirmpassword}
                                                    isValid={!!formik.touched.password && !formik.errors.confirmpassword}
                                                ></Form.Control>
                                                {formik.errors.password && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.confirmpassword}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            
                                            <Button type="submit" className="btn btn-block btn btn-success" variant="primary">Cambio Password</Button>


                                        </Form>
                                        <hr />
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
        </div>
        </div>
    );
}

