import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch } from "redux/store";
import { tokenAPICALL } from "services/authService";
import { setJWTToken } from "redux/actions";
import { setError } from "redux/slices/auth";
import { Alert, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
//import { getLoginByUsername } from "services/ws_loginService";
import { passwordAPICALL } from "services/passwordService";
import { Link } from "react-router-dom";
import img_jmyimprese from "../asset/Intersect.png"
import img_logotecno from "../asset/TecnoinnovisLogo.png"
import img_logomyimprese from "../asset/Logomyimprese.png"
import img_logomyvisure from "../asset/logo_myvisure.png"
import img_logopaypal from "../asset/logo_pp.png"
import img_logonexi from "../asset/logo_n.png"
import img_logomyimpresawhite from "../asset/logo_mi_white.png"
import logo from "../asset/logo_myimprese.png"
import NavigationMenu from "template/NavigationMenu";
import FooterPage from "template/FooterPage";

const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rData = useSelector((state: RootState) => state.authToken);
    
    const[invioEmail, setInvioEmail] = useState(null);
    
    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        onSubmit: async (values) => {
            setInvioEmail(true)
            //navigate('/register', { replace: true });

            // getLoginByUsername("aaaa").then((response) => {
            //     console.log(response)
            // })

            passwordAPICALL(values.username).then((response) => {
                console.log(response)
            })
        },
    });

    const handleRecuperPassword = () => {
        setInvioEmail(true)
        //navigate('/register', { replace: true });

        // getLoginByUsername("aaaa").then((response) => {
        //     console.log(response)
        // })
    }

    useEffect(() => {
        setInvioEmail(false)
        document.body.classList.toggle('bg-gradient-primary', true);
    }, [])

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLoging = () => {
    navigate('/', { replace: true });
  }

  const MenuItems = () => (

    <>
      <p onClick={()=>handleLoging()} className="font-menu cursor-pointer hover:text-blue-500 transition-colors">Home</p>
    </>
  );

    return (
        <>
        <div className="">
        <NavigationMenu isLoading={false}/>
            {/* <div className="nav-desktop row menu-basic align-items-center px-custom">
                <div className="col-sm-6">
                    <img src={logo} alt="" className="logo-public" />
                </div>
                <div className="col-sm-6">
                    <div className="d-flex justify-content-end gap-3">
                        <p  onClick={()=>handleLoging()} className="font-menu">Home</p>
                    </div>
                </div>
            </div>
            <div className="nav-mobile row menu-basic align-items-center px-custom relative paddind-lr-m-15 min-height-auto">
                <div className='row full-width'>
                    <div className="col-sm-6 dim-custom-80">
                        <img src={logo} alt="Logo" className="logo-public" />
                    </div>
                    <div className="col-sm-6 dim-custom-20 flex text-center">
                        <i className="fa fa-bars icon-bar-sm" onClick={toggleMobileMenu}></i>
                    </div>
            </div>
        {isMobileMenuOpen && (
                <div className="fixed inset-0 bg-white z-40 pt-20">
                    <div className="flex flex-col items-center space-y-6">
                        <MenuItems />
                    </div>
                </div>
                )}
            </div> */}
            <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-12 col-md-12">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Recupera Password</h1>
                                        </div>
                                        <Form className="user" onSubmit={formik.handleSubmit}>
                                            <Form.Group>
                                                <label className="form-control-label">Inserisci Email</label>
                                                <Form.Control type="text" name="username" className="form-control form-control-user" value={formik.values.username}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!formik.touched.username && !!formik.errors.username}
                                                    isValid={!!formik.touched.username && !formik.errors.username}
                                                ></Form.Control>
                                                {formik.errors.username && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.username}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <Button type="info" className="btn-user btn-block btn btn-info btn btn-primary" variant="primary" onClick={handleRecuperPassword} >Invia</Button>
                                        </Form>
                                        <hr />
                                        {rData.errorMessage ?
                                            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.errorMessage}
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setError(''))}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert> : null}
                                        {invioEmail &&
                                            <Alert variant={Constant.defaultAlertSuccess} className="alert-dismissible fade">Controlla la tua email per completare il recupero password
                                                {/* <Link className="close link-accedi" to={"/"}>
                                                    Ritorna alla Home
                                                </Link> */}
                                            </Alert>
                                        }
                                        <hr />
                                        {/* <div className="text-center">
                                            <a className="small" href="/">Vai alla login</a>
                                        </div>
                                        <div className="copyright text-center my-auto">
                                            <span>Copyright &copy; elenco imprese 2022</span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
        <FooterPage />
        {/* <div className="row box-footer-bg">
        <div className="col-sm-12">
            <div className="row box-footer-space">
                <div className="col-sm-4">
                    <p className="box-footer-col1-text">MyImprese è un servizio</p>
                    <img src={img_logotecno} className="box-footer-logo-tecno space-sm" />

                </div>
                <div className="col-sm-4">
                        <p className="box-footer-link"><a href="/privacy-policy" className="box-footer-link-color">Privacy Policy</a></p>
                        <p className="box-footer-link"><a href="/termini-condizioni" className="box-footer-link-color">Termini e Condizioni</a></p>
                        <p className="box-footer-link"><a href="/assistenza" className="box-footer-link-color">Contattaci</a></p>
                </div>
                <div className="col-sm-4">
                    <p className="box-footer-text-col3">Gestiamo pagamenti sicuri sulle piattaforme</p>
                    <p className="image-right"><img src={img_logopaypal} className="box-footer-logo-tecno box-footer-logo_1" /></p>
                    <p className="image-right"><img src={img_logonexi} className="box-footer-logo-tecno box-footer-logo_2" /></p>
                </div>

            </div>
        </div>
        
    </div>
    <div className="row box-copy">
        <div className="col-sm-12 p-top-20">
            <p className="t-align-center box-copy-text"><img src={img_logomyimpresawhite} className="box-copy-logo"/> - 2024</p>
        </div>
    </div> */}
    </>
    );
};

export default ResetPassword;


