import moment from "moment";
import React, { useState } from "react";
import { Col, Card, Row } from "react-bootstrap";
import { IPiano_Abbonamento } from "redux/slices/piano_abbonamento";
import { useNavigate } from "react-router";
type Props = {
    listaAbbonamento: IPiano_Abbonamento;
};
export const CardPagina: React.FC<Props> = ({ listaAbbonamento }) => {

    const navigate = useNavigate();

    const handleShop = () =>{
        navigate("/shop/", {replace: true})
    }

    const handleStatoAbbonamento = (stato) => {

        if(stato === 0){
            return <p className="color-icon-notfound color-blue">Non Attivo</p>
        }else{
            return <p className="color-blue">Attivo</p>
        }

    }

    return (
        <>
        
        <Row>
            <Col md={12} xl={12} xs={12} className="mb-4">
            <Card className="border-left-primary shadow h-100 py-2">
                <Card.Body>
                    <div className="row no-gutters align-items-center">
                        <div className="col-md-11 col-xs-10">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="text-xs font-weight-bold text-uppercase mb-1 color-gray-pg">
                                        Stato Abbonamento
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1 font-size-1-2">
                                        {handleStatoAbbonamento(listaAbbonamento.attivo)}
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                </div>
                                <div className="col-md-4">
                                <div className="text-xs font-weight-bold color-gray-pg text-uppercase mb-1">
                                        Data Attivazione
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                    <div className="text-xs font-weight-bold color-blue text-uppercase mb-1 font-size-1-2">
                                        {moment(listaAbbonamento.data_apertura).format('DD/MM/YYYY HH:mm:ss')}
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                </div>
                                {/* <div className="col-md-4">
                                <div className="text-xs font-weight-bold color-gray-pg text-uppercase mb-1">
                                        Data Scadenza
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                    <div className="text-xs font-weight-bold color-blue text-uppercase mb-1 font-size-1-2">
                                        {moment(listaAbbonamento.data_scadenza).format('DD/MM/YYYY HH:mm:ss')}
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-1  col-xs-2">
                            <i className="fas fa-clipboard-list fa-2x color-blue"></i>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Col>
        <Col md={4} xl={3} xs={6} className=" mb-4">
        <Card className="border-left-primary shadow h-100 py-2">
            <Card.Body>
                <div className="row no-gutters align-items-center">
                    <div className="col-md-10 col-xs-10">
                        <div className="col-xs-12">
                            <div className="text-xs font-weight-bold color-gray-pg text-uppercase mb-1">
                                Ricerca Impresa
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                        </div>
                        <div className="col-xs-12">
                            <div className="text-xs font-weight-bold color-blue mb-1 font-size-1-2">
                                {listaAbbonamento.numero_ricercaunica_residuo} di {listaAbbonamento.numero_ricercaunica_massimo}
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                        </div>
                    </div>
                    <div className="col-md-2  col-xs-2">
                        <i className="fas fa-search fa-2x text-gray-300"></i>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </Col>
            
        <Col md={4} xl={3} xs={6} className=" mb-4">
        <Card className="border-left-primary shadow h-100 py-2">
            <Card.Body>
                <div className="row no-gutters align-items-center">
                    <div className="col-md-10 col-xs-10">
                        <div className="col-xs-12">
                            <div className="text-xs font-weight-bold color-gray-pg text-uppercase mb-1">
                                Anagrafiche
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                        </div>
                        <div className="col-xs-12">
                            <div className="text-xs font-weight-bold color-blue mb-1 font-size-1-2">
                                {listaAbbonamento.numero_anagrafiche_residuo} di {listaAbbonamento.numero_anagrafiche_massimo}
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                        </div>
                    </div>
                    <div className="col-md-2  col-xs-2">
                        <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </Col>
    <Col md={4} xl={3} xs={6} className=" mb-4">
            <Card className="border-left-primary shadow h-100 py-2">
                <Card.Body>
                    <div className="row no-gutters align-items-center">
                        <div className="col-md-10 col-xs-10">
                            <div className="col-xs-12">
                                <div className="text-xs font-weight-bold color-gray-pg text-uppercase mb-1">
                                    Dettaglio Impresa
                                </div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                            </div>
                            <div className="col-xs-12">
                                <div className="text-xs font-weight-bold color-blue mb-1 font-size-1-2">
                                    {listaAbbonamento.numero_dettaglio_impresa_residuo} di {listaAbbonamento.numero_dettaglio_impresa}
                                </div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                            </div>
                        </div>
                        <div className="col-md-2  col-xs-2">
                            <i className="fa fa-list-alt fa-2x text-gray-300"></i>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Col> 
        <Col md={4} xl={3} xs={6} className=" mb-4">
        <Card className="border-left-primary shadow h-100 py-2">
            <Card.Body>
                <div className="row no-gutters align-items-center">
                    <div className="col-md-10 col-xs-10">
                        <div className="col-xs-12">
                            <div className="text-xs font-weight-bold color-gray-pg text-uppercase mb-1">
                                Visure Camerali
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                        </div>
                        <div className="col-xs-12">
                            <div className="text-xs font-weight-bold color-blue mb-1 font-size-1-2">
                                {listaAbbonamento.nunero_visure_residuo} di {listaAbbonamento.nunero_visure_massimo}
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                        </div>
                    </div>
                    <div className="col-md-2  col-xs-2">
                        <i className="fa-regular fa-file fa-2x text-gray-300"></i>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </Col>
    <Col md={4} xl={3} xs={6} className=" mb-4">
            <Card className="border-left-primary shadow h-100 py-2">
                <Card.Body>
                    <div className="row no-gutters align-items-center">
                        <div className="col-md-10 col-xs-10">
                            <div className="col-xs-12">
                                <div className="text-xs font-weight-bold color-gray-pg text-uppercase mb-1">
                                    Bilanci Azienda
                                </div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                            </div>
                            <div className="col-xs-12">
                                <div className="text-xs font-weight-bold color-blue mb-1 font-size-1-2">
                                    {listaAbbonamento.nunero_bilanci_residuo} di {listaAbbonamento.nunero_bilanci_massimo}
                                </div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                            </div>
                        </div>
                        <div className="col-md-2  col-xs-2">
                            <i className="fa fa-pie-chart fa-2x text-gray-300"></i>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Col>
        <Col md={4} xl={3} xs={6} className=" mb-4">
        <Card className="border-left-primary shadow h-100 py-2">
            <Card.Body>
                <div className="row no-gutters align-items-center">
                    <div className="col-md-10 col-xs-10">
                        <div className="col-xs-12">
                            <div className="text-xs font-weight-bold color-gray-pg text-uppercase mb-1">
                                Report Impresa Smart
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                        </div>
                        <div className="col-xs-12">
                        <div className="text-xs font-weight-bold color-blue mb-1 font-size-1-2">
                                    {listaAbbonamento.numero_reportsmart_residuo} di {listaAbbonamento.numero_reportsmart_massimo}
                                </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                        </div>
                    </div>
                    <div className="col-md-2  col-xs-2">
                        <i className="fa fa-line-chart fa-2x text-gray-300"></i>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </Col>
        <Col md={4} xl={3} xs={6} className=" mb-4">
        <Card className="border-left-primary shadow h-100 py-2">
            <Card.Body>
                <div className="row no-gutters align-items-center">
                    <div className="col-md-10 col-xs-10">
                        <div className="col-xs-12">
                            <div className="text-xs font-weight-bold color-gray-pg text-uppercase mb-1">
                                Deep Dossier
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                        </div>
                        <div className="col-xs-12">
                            <div className="text-xs font-weight-bold color-blue mb-1 font-size-1-2">
                                {listaAbbonamento.numero_deepdossier_residuo} di {listaAbbonamento.numero_deepdossier_massimo}
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                        </div>
                    </div>
                    <div className="col-md-2  col-xs-2">
                        <i className="fa fa-line-chart fa-2x text-gray-300"></i>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </Col>
    <Col md={4} xl={3} xs={6} className=" mb-4">
            <Card className="border-left-primary shadow h-100 py-2">
                <Card.Body>
                    <div className="row no-gutters align-items-center">
                        <div className="col-md-10 col-xs-10">
                            <div className="col-xs-12">
                                <div className="text-xs font-weight-bold color-gray-pg text-uppercase mb-1">
                                    Executive Report
                                </div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                            </div>
                            <div className="col-xs-12">
                                <div className="text-xs font-weight-bold color-blue mb-1 font-size-1-2">
                                {listaAbbonamento.numero_executivereport_residuo} di {listaAbbonamento.numero_executivereport_massimo}
                                </div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                            </div>
                        </div>
                        <div className="col-md-2  col-xs-2">
                            <i className="fa fa-line-chart fa-2x text-gray-300"></i>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Col> 
        
    </Row>
    <Row>
                                    <div className="col-sm-2"></div>
                                    <div className="col-sm-8">
                                        <p className="box-message-general">Acquista nuovi crediti e scopri i pacchetti più convenienti per le tue esigenze</p>
                                        <p className="box-message-general">sul nostro <span onClick={() => handleShop()}  className="box-message-span-shop">Shop</span></p>
                                    </div>
                                    <div className="col-sm-2"></div>
                                
    </Row>
        </>
        
    );
};