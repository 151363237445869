import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';

type Props = {
    show?: boolean;
    title: ReactNode;
    body?: ReactNode;
    buttonPositive?: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive?: () => void;
    onNegative: () => void;
};

const ConfirmationInfoAcquisto: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    return (
        <Modal size="lg" show={true} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='row'>
                                <div className='col-sm-12'>
                                    <p className='icon-alert color-blue'><i className="fa fa-circle-info"></i></p>
                                </div>
                                <div className='col-sm-12'>
                                    <p className=''>
                                    Per accedere ai servizi e iniziare le tue ricerche, è necessario acquistare almeno uno dei seguenti pacchetti: 
    <strong> Premium, Gold, Silver, Start, Base</strong> o <strong>Essential</strong>.  
    Puoi aggiungere fin da subito, o in un secondo momento, uno dei servizi disponibili nella sezione <strong>Pacchetti Aggiuntivi</strong>.
                                     
                                    </p>
                                </div>
                            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                    {buttonNegative}
                </Button>
                {/* <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                    {buttonPositive}
                </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationInfoAcquisto;

